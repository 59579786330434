import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { SellingPrice } from "../../../../components/modules/sales/selling-price";
import SellingPriceMiddleware from "../../../../middleware/modules/sales/selling-price";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares
const mapStateToProps = ({ auth, sellingPrice }) => ({
  authUser:auth.authUser,
  isProcessing: sellingPrice.isProcessing,
  isError: sellingPrice.isError,
  errorMessage: sellingPrice.errorMessage,
  datalist: sellingPrice.data,
  showModal: sellingPrice.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => SellingPriceMiddleware.create(),
      reset: () => SellingPriceMiddleware.resetList(),
      approve: (id, value, search) => SellingPriceMiddleware.beforeApprove(id, value, search),
      reject: (id, value, search) => SellingPriceMiddleware.beforeReject(id, value, search),
      search: (data) => SellingPriceMiddleware.list(data),
      update: (data) => SellingPriceMiddleware.update(data),
      delete: (id, search) => SellingPriceMiddleware.beforeDelete(id, search),
      getHelperFull: (id, search) =>
        SellingPriceMiddleware.getHelper(id, search),
    },
    dispatch
  );
};
class SellingPriceContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.approveItem = this.approveItem.bind(this);
    this.rejectItem = this.rejectItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.getHelperFull();
    this.props.update(id);
  }
  createItem() {
    this.props.create();
    this.props.getHelperFull();
  }
  approveItem(id, value, search) {
    this.props.approve(id, value, search);
  }
  rejectItem(id, value, search) {
    this.props.reject(id, value, search);
  }
  render() {
    const { isProcessing, search, datalist, showModal, authUser } = this.props;
    console.log(authUser)
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Selling Price List")}
          </title>
        </Helmet>
      <SellingPrice
        authUser={authUser}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        approveItem={this.approveItem}
        rejectItem={this.rejectItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SellingPriceContainer);
