import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { PurchaseRecap } from "../../../../components/modules/purchasing/purchase-recap";
// middlewares
import PurchaseRecapMiddleware from "../../../../middleware/modules/purchasing/purchase-recap";
import ItemMiddleware from "../../../../middleware/modules/master/item";
import UnitMiddleware from "../../../../middleware/modules/master/unit";
import SupplierMiddleware from "../../../../middleware/modules/master/supplier";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";

const mapStateToProps = ({ auth, purchaserecap }) => ({
  isProcessing: purchaserecap.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  datalist: purchaserecap.data,
  showModal: purchaserecap.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: (search) => PurchaseRecapMiddleware.create(search),
      search: (data) => PurchaseRecapMiddleware.list(data),
      update: (data) => PurchaseRecapMiddleware.update(data),
      delete: (id, search) => PurchaseRecapMiddleware.beforeDelete(id, search),
      convert: (id, search) =>
        PurchaseRecapMiddleware.beforeConvert(id, search),
      reset: () => PurchaseRecapMiddleware.resetList(),
      //helpers
      getSupplier: () => SupplierMiddleware.getHelper(),
      getItem: () => ItemMiddleware.getHelper(),
      getUnit: () => UnitMiddleware.getHelper(),
      getHelper: () => PurchaseRecapMiddleware.getHelper(),
    },
    dispatch
  );
};
class PurchaseRecapContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: {},
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.convertItem = this.convertItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  convertItem(id, search) {
    this.props.convert(id, search);
  }
  updateItem(id) {
    this.props.update(id);
    this.props.getHelper();
  }
  createItem(search = null) {
    this.props.create(search);
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Recap Purchase List")}</title>
        </Helmet>
        <PurchaseRecap
          search={search}
          data={datalist}
          isProcessing={isProcessing}
          showModal={showModal}
          deleteItem={this.deleteItem}
          updateItem={this.updateItem}
          createData={this.createItem}
          convertItem={this.convertItem}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseRecapContainer);
