import { faCamera, faList, faLocation, faMarker, faMicrophone, faMobilePhone, faOtter, faPhone, faPhotoFilm, faPhotoVideo, faRectangleList, faWifi } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from '../../../../i18n';
import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
const MySwal = withReactContent(Swal);

const Download=(props)=>  {
    const [data,setData]=useState(null)
    const token = cookieManager.getUserToken(); // Ambil token dari localStorage

    useEffect(()=>{
        getVersion();
    },[])
    const getVersion=async()=>{
        
         axios({
            metdod: 'get',
            url:process.env.REST_URL+ 'apps/version', // URL untuk mengunduh APK
            headers: {
              Authorization: `Bearer ${token}`, // Menggunakan token di header
            },
            responseType: 'json', // Untuk mendownload file
          }).then((response)=>{
            if(response.status){
                setData(response.data.result)
            }else{
                setData(null)
            }
          }).catch((error)=>{
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
                MySwal.hideLoading()
                MySwal.update({
                title:  i18n.t('Error'),
                html: i18n.t('Internal Server Error'),
                icon:  i18n.t('error'),
                showConfirmButton: true,
          })
        })
    }
  const handleDownload = async () => {
        MySwal.fire({
            title: i18n.t("Getting APK from server"),
            html: i18n.t("Please Wait ...."),
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        MySwal.showLoading();
        try {
          const response = await axios({
            metdod: 'get',
            url:process.env.UPLOAD_URL+ 'download/apk', // URL untuk mengunduh APK
            headers: {
                Authorization: `Bearer ${token}`, // Menggunakan token di header
              'X-Requested-With':process.env.KEY_ASSETS
            },
            responseType: 'blob', // Untuk mendownload file
          });
    
          // Membuat URL untuk file yang didownload
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement('a');
          a.href = url;
          a.download = 'cps.apk'; // Nama file APK yang akan diunduh
          a.click();
          MySwal.fire({
            title:  i18n.t('Success'),
            html:  i18n.t('Success Download APK'),
            icon:  i18n.t('success'),
            showConfirmButton: true,
          })
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log(error.config);
                MySwal.hideLoading()
                MySwal.update({
                title:  i18n.t('Error'),
                html: i18n.t('Internal Server Error'),
                icon:  i18n.t('error'),
                showConfirmButton: true,
        })
        }
      };

    return (
      <div className="d-flex flex-column flex-center flex-column-fluid">
      
      <div className="d-flex flex-column flex-center text-center p-10">
     
        <div className="card card-flush w-lg-650px py-5">
          <div className="card-body py-15 py-lg-20">
           
            <h1 className="fw-bolder fs-2hx text-gray-900 mb-4">{i18n.t('Download Apps')}</h1>
            
            <div className="fw-semibold fs-6 text-gray-500 mb-7">{i18n.t('Minimum Requirement')}</div>
           
            <Table className='table table-bordered table-striped table-requirement'>
                <tbody>
                <tr>
                    <td align='right' className='pr-5 fw-bold'>{i18n.t('OS')}</td>
                    <td align='left' className='pl-5'>7.0 (Nougat)</td>
                </tr>
                <tr>
                    <td align='right' className='pr-5 fw-bold'>{i18n.t('RAM')}</td>
                    <td align='left' className='pl-5'>3 GB</td>
                </tr>
                <tr>
                    <td align='right' className='pr-5 fw-bold'>{i18n.t('FREE DISK SPACE')}</td>
                    <td align='left' className='pl-5'>1 GB</td>
                </tr>
                <tr>
                    <td align='right' className='pr-5 fw-bold'>{i18n.t('COMMS')}</td>
                    <td align='left' className='pl-5'>BLUETOOTH, GPS, CAMERA</td>
                </tr>
                <tr>
                    <td align='right' className='pr-5 align-top fw-bold'>{i18n.t('PERMISSIONS')}</td>
                    <td align='left' className='pl-5'>
                        <div className="xNUmN" bis_skin_checked="1">
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faPhone}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Phone')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('read phone status and identity')}</span></li>
                                </ul>
                            </div>
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faPhotoVideo}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Photos/Media/Files')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('read tde contents of your USB storage')}</span></li>
                                    <li><span>{i18n.t('modify or delete tde contents of your USB storage')}</span></li>
                                </ul>
                            </div>
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faList}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Storage')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('read tde contents of your USB storage')}</span></li>
                                    <li><span>{i18n.t('modify or delete tde contents of your USB storage')}</span></li>
                                </ul>
                            </div>
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faCamera}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Camera')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('take pictures and videos')}</span></li>
                                </ul>
                            </div>
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faMicrophone}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Microphone')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('record audio')}</span></li>
                                </ul>
                            </div>
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faLocation}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Location')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('approximate location (network-based)')}</span></li>
                                    <li><span>{i18n.t('precise location (GPS and network-based)')}</span></li>
                                </ul>
                            </div>
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faWifi}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Wi-Fi connection information')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('view Wi-Fi connections')}</span></li>
                                </ul>
                            </div>
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faMobilePhone}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Device ID & call information')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('read phone status and identity')}</span></li>
                                </ul>
                            </div>
                            <div className="BlLrjc" bis_skin_checked="1">
                                <div className="B4wkv" bis_skin_checked="1">
                                    <FontAwesomeIcon icon={faRectangleList}  style={{ marginRight: 5}} />
                                    <span className="pl-10 fw-bold">{i18n.t('Other')}</span>
                                </div>
                                <ul className="dnM39b">
                                    <li><span>{i18n.t('receive data from Internet')}</span></li>
                                    <li><span>{i18n.t('view network connections')}</span></li>
                                    <li><span>{i18n.t('change network connectivity')}</span></li>
                                    <li><span>{i18n.t('connect and disconnect from Wi-Fi')}</span></li>
                                    <li><span>{i18n.t('control flashlight')}</span></li>
                                    <li><span>{i18n.t('full network access')}</span></li>
                                    <li><span>{i18n.t('pair with Bluetooth devices')}</span></li>
                                    <li><span>{i18n.t('reorder running apps')}</span></li>
                                    <li><span>{i18n.t('control vibration')}</span></li>
                                    <li><span>{i18n.t('prevent device from sleeping')}</span></li>
                                    <li><span>{i18n.t('access Bluetooth settings')}</span></li>
                                    <li><span>{i18n.t('read Google service configuration')}</span></li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </Table>
           {data && 
           <div className="mb-0">
              <a href="#" onClick={(e) => { e.preventDefault(); handleDownload(); }} className="btn btn-sm btn-primary">Download {data?.name +' '+ data?.version }</a>
                {data?.last_update &&
                    <p className='text-muted text-center mt-3'>{i18n.t('Last Update : ') + moment(data?.last_update).format('DD MMM YYYY HH:mm:ss')}</p>
                }
            </div>}
           
          </div>
        </div>
       
      </div>
    </div>
    );
}

export default Download;
