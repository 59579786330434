import React, { Component } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import FormStock from "../../../../containers/modules/mutation/stock-card/form-stock";

const MySwal = withReactContent(Swal);
export class StockCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: 0,
      search: {
        limit: 25,
      },
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      titleModal: "",
      listData: [],
      listDataItem: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), className: " text-gray-600" },
        { link: null, title: i18n.t("Stock"), className: " text-gray-500" },
      ],
      columns: [
      ],
    };
  }

  render() {
    const {data}= this.props
    return (
      <div id="invoice-page">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">
                <FormStock
                    {...this.props}
                    data={data}
                  />
              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default StockCard;
