import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import StockCardMiddleware from '../../../../middleware/modules/mutation/stock-card';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import StockCard from "../../../../components/modules/mutation/stock-card";
// middlewares
const mapStateToProps = ({ auth, stockCard }) => ({
  authUser:auth.authUser,
  isProcessing: stockCard.isProcessing,
  isError: stockCard.isError,
  errorMessage: stockCard.errorMessage,
  helperList:stockCard.helperStockCard,
  data: stockCard.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => StockCardMiddleware.getHelper(),
      reset: () =>StockCardMiddleware.resetList(),
      getData: (data) => StockCardMiddleware.getData(data),

    },
    dispatch
  );
};
class StockCardContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      show: 0,
      auth : null,
      setData : 0,
    };
  }
  componentWillMount() {
    console.log('sini',this.props)
    console.log('aaa',this.state)
    const search = {
      limit: 10,
    };
    this.props.reset();

    // this.getData
    // this.props.getHelper();
    // this.props.getData(this.state.auth.employee_branch);
  }

  getData(id) {
    this.props.getData(id);
  }


  render() {
    const { authUser } = this.props;
    // console.log('state',this.state)
    // console.log('props',this.props)
    
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Stock Card List")}
          </title>
        </Helmet>
      <StockCard
        {...this.props}
        authUser={authUser}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StockCardContainer);
