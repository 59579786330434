import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import StockCardMiddleware from '../../../../middleware/modules/mutation/stock-card';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
import DataStock from "../../../../components/modules/mutation/stock-card/form-stock";
// middlewares
const mapStateToProps = ({ stockCard, }) => ({
  isProcessing: stockCard.isProcessing,
  isError: stockCard.isError,
  errorMessage: stockCard.errorMessage,
  helperList:stockCard.helperStockCard,
  data: stockCard.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => StockCardMiddleware.getHelper(),
      getData: (data) => StockCardMiddleware.getData(data),
      runSearch: (data) => StockCardMiddleware.searchData(data),
    },
    dispatch
  );
};
class FormStockContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    data: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      show: 0,
      auth : null,
      setData : 0
    };
    this.getData = this.getData.bind(this);
    this.runSearch = this.runSearch.bind(this);
  }

  getData(id) {
    this.props.getData(id);
  }

  runSearch(data){
    this.props.runSearch(data);
  }

  componentWillMount() {
    
  }

  render() {
    const { authUser, data } = this.props;
    if(authUser != null && this.state.setData == 0){
      this.state.auth = authUser.employee_branch;
      this.getData(this.state.auth);
      this.state.setData = 1;
    }
    // if(authUser!=null){
    //   this.state.auth = authUser;
    // }
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Stock Card List")}
          </title>
        </Helmet>
      <DataStock
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormStockContainer);
