import React, { Component, useState } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Modal } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/purchasing/purchase-recap/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
const MySwal = withReactContent(Swal);
export class PurchaseRecap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: null, title: i18n.t("Transaction"), class: " text-gray-600" },
        {
          link: null,
          title: i18n.t("Purchase Recap"),
          class: " text-gray-500",
        },
      ],

      column: [
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "date",
          filter: {
            active: false,
          },
          title: i18n.t("Action"),
          type: "action",
          tHclass: "text-center min-w-150px",
          tdClass: "text-center",
          form:'icon',
          buttons: [
            {
              key: null,
              btnText: i18n.t("Add Supplier"),
              visible: {
                key: "status_show",
                params: [1],
                function: (id, params) => {
                  return params.includes(id);
                },
              },
              btnFunction: (data, column) => {
                this.updateData(data.date);
              },
              bthClass: "btn btn-warning",
            },
            {
              key: null,
              btnText: i18n.t("Convert"),
              visible: {
                key: "status_show",
                params: [1],
                function: (id, params) => {
                  return params.includes(id);
                },
              },
              btnFunction: (data, column) => {
                if (data.canConvert > 0) {
                  this.convertItem(data.date);
                } else {
                  Swal.fire({
                    title: "Error",
                    text: i18n.t("Please Add Supplier And Quantity"),
                    icon: "error",
                  });
                }
              },
              bthClass: "btn btn-warning",
            },
          ],
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.createData = this.createData.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  createData() {
    this.state.titleModal = i18n.t("Add Purchase Request");
    this.props.createData();
  }
  updateData(date) {
    this.state.titleModal = i18n.t("Set Supplier Request");
    this.props.updateItem(date);
  }
  deleteData(id) {
    Swal.fire({
      title: i18n.t("Are you sure?"),
      text: i18n.t("User will have Admin Privileges"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.props.deleteItem(this.state.search, id);
      }
    });
  }
  convertItem(id) {
    const html = (
      <>
        <div>{i18n.t("You will Convert this PR")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            this.props.convertItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Converting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Convert")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  rejectData(id) {
    const html = (
      <>
        <div>{i18n.t("Reject This PO")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "text",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.rejectItem(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Reject")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal } = this.props;
    this.state.listData = data;
    return (
      <div id="PurchaseRecap-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-PurchaseRecaps-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  // checkAble={true}
                  numberAble={true}
                  expandAble={true}
                  theExpand={{
                    key: "req_detail",
                    columns: [
                      { label: i18n.t("No. Request"), key: "no_pr" },
                      { label: i18n.t("Supplier"), key: "name_supplier" },
                      { label: i18n.t("Item"), key: "name_item" },
                      { label: i18n.t("Qty Order"), key: "qtyOrder_format" },
                      { label: i18n.t("Unit"), key: "name_unit" },
                      {
                        label: i18n.t("Warehouse Mutation"),
                        key: "branch_name",
                      },
                      {
                        label: i18n.t("Qty Mutation"),
                        key: "qty_mutation_req_format",
                      },
                      { label: i18n.t("Status"), key: "status_due" },
                    ],
                  }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  // convertData={this.updateData}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormContainer
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default PurchaseRecap;
