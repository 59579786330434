import React, { Fragment } from "react"
import i18n from "../../../../i18n";
import LabelTranscation from "../../../utilities/Status/transaction";
import { formatCurrency } from "../../../utilities/Class/FormatNumber";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
const squareStyle = {
    width: "100%",
    height: "auto",
    border: "3px dotted grey",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
};
export default function DataListFull(data) {
    let arr = data.data;
    let back = data.backPage
    console.log('PR',data)
    return (
        <div className="card mb-12 mb-xl-8">

        {/* DAFTAR PERMINTAAN */}
            {arr.pr != null ?
                <Fragment key={'req-full'}>
                    <div className="row">
                        <div className="col-auto text-end">
                            <Button
                                key={"secondary"}
                                type="button"
                                className="btn-sm"
                                variant="secondary"
                                onClick={(e) => back(true)}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowLeftLong}
                                ></FontAwesomeIcon>
                                {i18n.t("  Kembali")}
                            </Button>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col text-center"><h1> {i18n.t("Detail Transaksi")}</h1></div>
                    </div>
                    <div className="row mt-5">
                        <div className="col text-left mt-3">
                            <h2>{i18n.t("Purchase Request")}</h2>
                        </div>
                        <div style={squareStyle} className="row w-100">
                            <div className="row w-100">
                                <div className="col text-left">
                                    <table>
                                        <tbody className="text-gray-800 fw-bold fs-4">
                                            <tr>
                                                <td>
                                                    {i18n.t('No. Purchase Request')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {arr.pr.no_pr}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('Date')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {arr.pr.date}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row w-100 mt-3" key={'pr-head'+arr.pr.id}>
                                {arr.pr.detail != null ? renderPR(arr.pr.detail) : null}
                            </div>
                        </div>


                    </div>
                </Fragment>
                : null
            }

        {/* DAFTAR PEMESANAN     */}
            {arr.po != null ?
                <Fragment key={'po-full'}>
                    <div className="row mt-5">
                        <div className="col text-left">
                            <h2>{i18n.t("Purchase Order")}</h2>
                        </div>

                        <div style={squareStyle} className="row w-100">
                            <div className="row w-100">
                                <div className="col text-left mt-3">
                                {arr.po.length > 0 ? arr.po.map((item,i)=>{
                                    return (
                                        <Fragment>
                                        <table>
                                        <tbody className="text-gray-800 fw-bold fs-4">
                                            <tr>
                                                <td>
                                                    {i18n.t('No. Purchase Order')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.po_no}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('Date')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.date}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="row w-100 mt-3">
                                        {item.detail != null ? renderPO(item.detail) : null}
                                    </div>
                                    <br/>
                                    <hr/>
                                    </Fragment>
                                    );

                                    
                                })
                                :
                                <div className="col text-center mt-3">
                                    <h2>{i18n.t("Not Have Information About Purchase Order !")}</h2>
                                </div>
                                }
                                    
                                </div>
                            </div>
                           
                        </div>


                    </div>
                </Fragment>
                : 
                null
            }

        {/* DAFTAR PENERIMAAN */}
            {arr.rec != null ?
                <Fragment key={'rec-full'}>
                    <div className="row mt-5">
                        <div className="col text-left">
                            <h2>{i18n.t("Receiving")}</h2>
                        </div>

                        <div style={squareStyle} className="row w-100">
                            <div className="row w-100">
                                <div className="col text-left mt-3">
                                { arr.rec.length > 0 ? arr.rec.map((item,i)=>{
                                    return (
                                        <Fragment>
                                        <table>
                                        <tbody className="text-gray-800 fw-bold fs-4">
                                            <tr>
                                                <td>
                                                    {i18n.t('No. Receiving')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.rec_no}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('Date')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.date}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('No. Document')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.document_no}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('No. Invoice')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.no_invoice}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="row w-100 mt-3">
                                        {item.detail != null ? renderReceiving(item.detail) : null}
                                    </div>
                                    <br/>
                                    <hr/>
                                    </Fragment>
                                    );

                                    
                                })
                                : 
                                <div className="col text-center mt-3">
                                    <h2>{i18n.t("Not Have Information About Receiving !")}</h2>
                                </div>
                                }
                                    
                                </div>
                            </div>
                           
                        </div>


                    </div>
                </Fragment>
                :  null
            }

        {/* DAFTAR PEMBELIAN */}
            {arr.inv != null ?
                <Fragment key={'inv-full'}>
                    <div className="row mt-5">
                        <div className="col text-left">
                            <h2>{i18n.t("Invoice")}</h2>
                        </div>

                        <div style={squareStyle} className="row w-100">
                            <div className="row w-100">
                                <div className="col text-left mt-3">
                                {arr.inv.length > 0 ? arr.inv.map((item,i)=>{
                                    return (
                                        <Fragment>
                                        <table>
                                        <tbody className="text-gray-800 fw-bold fs-4">
                                            <tr>
                                                <td>
                                                    {i18n.t('No. Invoice')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.inv_no}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('Date')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.date}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {i18n.t('Due Date')}
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td>
                                                    {item.due_date ?? '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className="row w-100 mt-3">
                                        {item.detail != null ? renderInvoice(item.detail) : null}
                                    </div>
                                    <br/>
                                    <hr/>
                                    </Fragment>
                                    );

                                    
                                })
                                : 
                                <div className="col text-center mt-3">
                                    <h2>{i18n.t("Not Have Information About Invoice !")}</h2>
                                </div>
                                }
                                    
                                </div>
                            </div>
                           
                        </div>


                    </div>
                </Fragment>
                :  null
            }

        {/* DAFTAR PEMBAYARAN */}
            
                <Fragment key={'pay-full'}>
                    <div className="row mt-5">
                        <div className="col text-left">
                            <h2>{i18n.t("Payment")}</h2>
                        </div>
                        <div style={squareStyle} className="row w-100">
                            <div className="row w-100">
                            {arr.pay != null && arr.pay.length > 0?
                            <Fragment>
                                <div className="col text-left mt-3">
                                    <table className="table table-responsive table-index " style={{ fontSize: '16px' }}>
                                        <thead className="bg-success text-white text-center">
                                            <tr className="border text-center" key={'inv-head'}>
                                                <th width={"5%"} className="text-center">{i18n.t("No")}</th>
                                                <th width={"15%"} >{i18n.t("Date")}</th>
                                                <th width={"15%"} >{i18n.t("No. Payment")}</th>
                                                <th width={"10%"} >{i18n.t("Payment Type")}</th>
                                                <th width={"15%"}>{i18n.t("No Invoice")}</th>
                                                <th width={"10%"}>{i18n.t("Total")}</th>
                                                <th width={"10%"}>{i18n.t("Payment")}</th>
                                                <th width={"10%"}>{i18n.t("Remaining")}</th>
                                                <th width={"10%"}>{i18n.t("Status")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className="border ">
                                            {arr.pay.length > 0 ? arr.pay.map((item,i)=>{
                                                return (
                                                    renderPayment(item,i)
                                                );
                                            })
                                            : null }
                                        </tbody>
                                    </table>       
                                </div>
                                </Fragment>
                                :  <div className="col text-center mt-3"> 
                                                <h2>{i18n.t("Not Have Information About Payment !")}</h2>
                                            </div>
                                    }
                            </div>
                        </div>
                    </div>
                </Fragment>
                
        </div>
    )

    function renderPR(data) {
        let arr = data;
        console.log(arr)
        let html = [];
        html.push(
            <Fragment>
                <div className="row">
                    <table className="table table-responsive table-index " style={{ fontSize: '16px' }}>
                        <thead className="bg-success text-white text-center">
                            <tr className="border text-center">
                                <th width={"5%"} className="text-center">{i18n.t("No")}</th>
                                <th width={"25%"} >{i18n.t("Item Name")}</th>
                                <th width={"25%"}>{i18n.t("Unit")}</th>
                                <th width={"25%"}>{i18n.t("Qty")}</th>
                                <th width={"20%"}>{i18n.t("Status")}</th>
                            </tr>
                        </thead>
                        <tbody className="border ">
                            {arr.length != null  ?
                                arr.map((item, s) => {
                                    return(
                                    <tr className="border text-center" key={'request'+s} >
                                        <td style={{ padding: '5px', textAlign: 'center' }}>{(s + 1)}</td>
                                        <td style={{ padding: '5px', textAlign: 'center' }}>{item.item_name}</td>
                                        <td style={{ padding: '5px', textAlign: 'center' }}>{item.unit_name}</td>
                                        <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.qty,0))}</td>
                                        <td style={{ padding: '5px', textAlign: 'center' }}>
                                            <LabelTranscation value={item.status} />
                                        </td>
                                    </tr>)
                                })
                                : null}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );

        return html;
    }

    function renderPO(data) {
        let arr = data;
        let html = [];
        html.push(
            <Fragment>
                <div className="row">
                    <table className="table table-responsive table-index " style={{ fontSize: '16px' }}>
                        <thead className="bg-success text-white text-center">
                            <tr className="border text-center">
                                <th width={"5%"} className="text-center">{i18n.t("No")}</th>
                                <th width={"20%"} >{i18n.t("Item Name")}</th>
                                <th width={"10%"}>{i18n.t("Unit")}</th>
                                <th width={"10%"}>{i18n.t("Qty")}</th>
                                <th width={"10%"}>{i18n.t("Price")}</th>
                                <th width={"10%"}>{i18n.t("Discount")}</th>
                                <th width={"10%"}>{i18n.t("Tax")}</th>
                                <th width={"10%"}>{i18n.t("Nett Price")}</th>
                                <th width={"15%"}>{i18n.t("Total")}</th>
                            </tr>
                        </thead>
                        <tbody className="border ">
                            {arr != null ?
                                arr.map((item, s) => {
                                    return (
                                        <tr className="border text-center" key={'order'+s}>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{(s + 1)}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.item_name}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.unit_name}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.qty,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.price,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.discount,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.tax,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.price_nett,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.total,0))}</td>
                                            
                                        </tr>)
                                })
                                : null}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );

        return html;
    }

    function renderReceiving(data) {
        let arr = data;
        let html = [];
        html.push(
            <Fragment>
                <div className="row">
                    <table className="table table-responsive table-index " style={{ fontSize: '16px' }}>
                        <thead className="bg-success text-white text-center">
                            <tr className="border text-center">
                                <th width={"5%"} className="text-center">{i18n.t("No")}</th>
                                <th width={"10%"} >{i18n.t("No. Order")}</th>
                                <th width={"15%"} >{i18n.t("Item Name")}</th>
                                <th width={"10%"}>{i18n.t("Unit")}</th>
                                <th width={"15%"}>{i18n.t("Warehouse")}</th>
                                <th width={"15%"}>{i18n.t("Sub Warehouse")}</th>
                                <th width={"10%"}>{i18n.t("Qty Order")}</th>
                                <th width={"10%"}>{i18n.t("Qty Receiving")}</th>
                                <th width={"10%"}>{i18n.t("Qty Remaining")}</th>
                            </tr>
                        </thead>
                        <tbody className="border ">
                            {arr != null ?
                                arr.map((item, s) => {
                                    return (
                                        <tr className="border text-center" key={'receiving'+s} >
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{(s + 1)}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.po_no}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.item_name}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.unit_name}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.warehouse}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.sub_warehouse}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.qty_order,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.qty_receiving,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.qty_remaining,0))}</td>
                                        </tr>)
                                })
                                : null}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );

        return html;
    }

    function renderInvoice(data) {
        let arr = data;
        let html = [];
        html.push(
            <Fragment>
                <div className="row">
                    <table className="table table-responsive table-index " style={{ fontSize: '16px' }}>
                        <thead className="bg-success text-white text-center">
                            <tr className="border text-center" key={'inv-head'}>
                                <th width={"5%"} className="text-center">{i18n.t("No")}</th>
                                <th width={"10%"} >{i18n.t("No. Receiving")}</th>
                                <th width={"15%"} >{i18n.t("Item Name")}</th>
                                <th width={"5%"}>{i18n.t("Unit")}</th>
                                <th width={"10%"}>{i18n.t("Warehouse")}</th>
                                <th width={"10%"}>{i18n.t("Sub Warehouse")}</th>
                                <th width={"7%"}>{i18n.t("Qty")}</th>
                                <th width={"7%"}>{i18n.t("Price")}</th>
                                <th width={"7%"}>{i18n.t("Discount")}</th>
                                <th width={"7%"}>{i18n.t("Tax")}</th>
                                <th width={"7%"}>{i18n.t("Nett Price")}</th>
                                <th width={"10%"}>{i18n.t("Total")}</th>
                            </tr>
                        </thead>
                        <tbody className="border ">
                            {arr != null ?
                                arr.map((item, s) => {
                                    return (
                                        <tr className="border text-center" key={'invoice'+s}>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{(s + 1)}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.rec_no}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.item_name}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.unit_name}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.warehouse}</td>
                                            <td style={{ padding: '5px', textAlign: 'center' }}>{item.sub_warehouse}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.qty,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.price,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.discount,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.tax,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.price_nett,0))}</td>
                                            <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(item.total,0))}</td>
                                            
                                        </tr>)
                                })
                                : null}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );

        return html;
    }

    function renderPayment(data,i) {
        let arr = data;
        let html = [];
        html.push(
            <Fragment>
                {arr != null ?
                    <tr className="border text-center" key={'payment'+i}>
                        <td style={{ padding: '5px', textAlign: 'center' }}>{(i+1)}</td>
                        <td style={{ padding: '5px', textAlign: 'center' }}>{arr.date}</td>
                        <td style={{ padding: '5px', textAlign: 'center' }}>{arr.pay_no}</td>
                        <td style={{ padding: '5px', textAlign: 'center' }}>{arr.payment_type}</td>
                        <td style={{ padding: '5px', textAlign: 'center' }}>{arr.inv_no}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(arr.total,0))}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(arr.pay,0))}</td>
                        <td style={{ padding: '5px', textAlign: 'right' }}>{(formatCurrency(arr.remaining,0))}</td>
                        <td style={{ padding: '5px', textAlign: 'center' }}>{arr.status}</td>
                    </tr>
                    
                    : null}
                
            </Fragment>
        );

        return html;
    }
}



