import TrackingPurchasingActions from './actions';

const INITIAL_STATE = {
  data: {},
  dataTracking: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form:{},
  isNewRecord:false,
  showModal:false,
  helperTrackingPurchasing:null,
  timeline:null,
  details:null,
  loadingDetails:false
};

function TrackingPurchasingReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TrackingPurchasingActions.RESET_DATA:
      return INITIAL_STATE;
    case TrackingPurchasingActions.NEW_DATA:
      return { ...state, isProcessing: false,single:null, form:INITIAL_STATE.form,isNewRecord:true,showModal:true};
    case TrackingPurchasingActions.GET_HELPER_REPORTVISIT:
        return { 
          ...state,
          isProcessing: false, 
          helperTrackingPurchasing: action.payload 
        };
      case TrackingPurchasingActions.GET_DATA:
        return { 
          ...state,
          isError: false,
          isProcessing: true, 
      };
      case TrackingPurchasingActions.SET_TRACKING:
        return { 
          ...state,
          isProcessing: false, 
          isError: false,
          dataTracking: action.payload 
        };
      case TrackingPurchasingActions.ERROR_DATA:
          return { 
            ...state, errorInternal:false, 
            isError: true,onSubmit:false,  
            errorMessage: action.payload,
            isProcessing:false 
        };
      case TrackingPurchasingActions.LOADED_DETAILS:
        return { 
          ...state,
          details: action.payload, 
          loadingDetails: false
        };
      case TrackingPurchasingActions.LOADING_DETAILS:
        return { 
          ...state,
          details: null, 
          loadingDetails: true
        };
    default:
      return state;
    }
}


export default TrackingPurchasingReducer;
