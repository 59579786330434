import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Payment } from "../../../../components/modules/purchasing/payment";
import PaymentMiddleware from "../../../../middleware/modules/purchasing/payment";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
// middlewares
const mapStateToProps = ({ auth, payment }) => ({
  isProcessing: payment.isProcessing,
  isError: payment.isError,
  errorMessage: payment.errorMessage,
  data: payment.data,
  showModal: payment.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => PaymentMiddleware.create(),
      reset: () => PaymentMiddleware.resetList(),
      search: (data) => PaymentMiddleware.list(data),
      update: (data) => PaymentMiddleware.update(data),
      delete: (id, search) => PaymentMiddleware.beforeDelete(id, search),
      getHelperFull: (id, search) =>
        PaymentMiddleware.getHelperFull(id, search),
      payment: (id, type) => PaymentMiddleware.getPayment(id, type),
    },
    dispatch
  );
};
class PaymentContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    data: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    data: {},
  };

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  render() {
    return (
      <React.Fragment>
      <Helmet>
        <title>
          {i18n.t("Payment")}
        </title>
      </Helmet>
    <Payment {...this.props} />
    </React.Fragment>
    );
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);
