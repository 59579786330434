import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import PrintInvoice from "../../../components/utilities/print/default/invoice";
// middlewares
import AuthMiddleware from "../../../reducers/auth/middleware";
import InvoiceMiddleware from "../../../middleware/modules/purchasing/invoice";

const mapStateToProps = ({ auth, invoice }) => ({
  isAuthenticated: auth.isAuthenticated,
  isRegistered: auth.isRegistered,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  isProcessing: invoice.isProcessing,
  dataPrint: invoice.printInvoice,
  requestPrint:invoice.requestPrintInvoice
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPrint: (id) => InvoiceMiddleware.getPrint(id),
    },
    dispatch
  );
};

class InvoiceContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    requestPrint:PropTypes.bool
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
    requestPrint:false
  };

  UNSAFE_componentWillMount() {
    this.props.getPrint(this.props.id);
  }

  render() {
    return <PrintInvoice {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceContainer);
