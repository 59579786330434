export default class TakingGoodsActions {

    static NEW_DATA = 'NEW_DATA';
    static UPDATE_DATA='UPDATE_DATA';
    static SHOW_DATA='SHOW_DATA';
    static VIEW_DATA='VIEW_DATA';
    static DELETE_DATA='DELETE_DATA';
    static ON_SUBMIT='ON_SUBMIT';
    static ERROR_DATA='ERROR_DATA';
    static PROCESS_DATA='PROCESS_DATA';
    static RESET_DATA='RESET_DATA';
    static SUCCESS_DATA='SUCCESS_DATA';
    static CANCEL_DATA = 'CANCEL_DATA';
    static GET_HELPER_TAKINGGOODS_FULL='GET_HELPER_TAKINGGOODS_FULL';
    static ERROR_SERVER='ERROR_SERVER';
    static RESET_LIST='RESET_LIST';
    static PRINT_TAKING_GOODS='PRINT_TAKING_GOODS';
    static BEFORE_PRINT_TAKING_GOODS='BEFORE_PRINT_TAKING_GOODS';
    static APPROVE='APPROVE';
    static REJECT='REJECT';
    static RETURN_DATA='RETURN_DATA';
    static VIEW_ITEM_DATA='VIEW_ITEM_DATA';
  

    static approve() {
      return {
        type: TakingGoodsActions.APPROVE
      };
    }
    static reject() {
      return {
        type: TakingGoodsActions.REJECT
      };
    }
    
    static submit(){
      return {
        type: TakingGoodsActions.ON_SUBMIT
      };
    }
  
    static create(data) {
      return {
        type: TakingGoodsActions.NEW_DATA,
        payload: data
      };
    }
  
    static update(data) {
      return {
        type: TakingGoodsActions.UPDATE_DATA,
        payload: data
      };
    }

    static return(data) {
      return {
        type: TakingGoodsActions.RETURN_DATA,
        payload: data
      };
    }

    static viewItem(data) {
      return {
        type: TakingGoodsActions.VIEW_ITEM_DATA,
        payload: data
      };
    }
  
    static view(data) {
      return {
        type: TakingGoodsActions.VIEW_DATA,
        payload: data
      };
    }
  
    static delete(data) {
      return {
        type: TakingGoodsActions.DELETE_DATA
      };
    }
  
    static list(data) {
      return {
        type: TakingGoodsActions.SHOW_DATA,
        payload: data
      };
    }
    static error(error){
      return {
        type: TakingGoodsActions.ERROR_DATA,
        payload: error
      };
    }
    static success(){
      return {
        type: TakingGoodsActions.SUCCESS_DATA,
      };
    }
    static process(){
      return {
        type: TakingGoodsActions.PROCESS_DATA,
      };
    }
    static reset(){
      return {
        type: TakingGoodsActions.RESET_DATA,
      };
    }

    static helperFull(data){
        return {
          type: TakingGoodsActions.GET_HELPER_TAKINGGOODS_FULL,
          payload:data
        };
      }

      static cancel() {
        return {
          type: TakingGoodsActions.CANCEL_DATA
        };
      }

      static resetList(){
        return {
          type: TakingGoodsActions.RESET_LIST,
        };
      }

      static errorInternal(error){
        return {
          type: TakingGoodsActions.ERROR_SERVER,
          payload: error
        };
      }

      static beforePrint(){
        return {
          type: TakingGoodsActions.BEFORE_PRINT_TAKING_GOODS,
        };
      }
      static print(data){
        return {
          type: TakingGoodsActions.PRINT_TAKING_GOODS,
          payload: data
        };
      }
  }