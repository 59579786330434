import React, { Component, Fragment, lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Routes, Route, useParams, useLocation} from "react-router-dom";
import LayoutFull from "./Layout";
import LoginLayout from "./LoginLayout";
import Home from "../../containers/HomeContainer";
import Login from "../Login";
import Page404 from "../../components/utilities/404";
import Customer from "../../containers/modules/master/customer";
import TempCustomer from "../../containers/modules/master/customer-temp";
import CustomerType from "../../containers/modules/master/customer-type";
import Store from "../../containers/modules/master/store";
import Province from "../../containers/modules/master/province";
import City from "../../containers/modules/master/city";
import District from "../../containers/modules/master/district";
import SubDistrict from "../../containers/modules/master/sub-district";
import Postalcode from "../../containers/modules/master/postalcode";
import Branch from "../../containers/modules/master/branch";
import Warehouse from "../../containers/modules/master/warehouse";
import Subwarehouse from "../../containers/modules/master/subwarehouse";
import Supplier from "../../containers/modules/master/supplier";
import Vehicle from "../../containers/modules/master/vehicle";
import Tax from "../../containers/modules/master/tax";
import Item from "../../containers/modules/master/item";
import Unit from "../../containers/modules/master/unit";
import ItemType from "../../containers/modules/master/item-type";
import { bindActionCreators } from "redux";
import AuthMiddleware from "../../reducers/auth/middleware";
import Offering from "../../containers/modules/purchasing/offering";
import PurchaseRequest from "../../containers/modules/purchasing/purchase-request";
import LogoutContainer from "../../containers/LogoutContainer";
import PurchaseRecap from "../../containers/modules/purchasing/purchase-recap";
import PurchaseOrder from "../../containers/modules/purchasing/purchase-order";
import Receiving from "../../containers/modules/purchasing/receiving";
import PrintPo from "../Print/default/Po";
import SetupWarehouse from "../../containers/modules/master/setup-warehouse";
import Coa1 from "../../containers/modules/accounting/coa1";
import Coa2 from "../../containers/modules/accounting/coa2";
import Coa3 from "../../containers/modules/accounting/coa3";
import Coa4 from "../../containers/modules/accounting/coa4";
import Coa5 from "../../containers/modules/accounting/coa5";
import AccSetup from "../../containers/modules/accounting/acc-setup";
import Invoice from "../../containers/modules/purchasing/invoice";
import Payment from "../../containers/modules/purchasing/payment";
import WarehouseOut from "../../containers/modules/warehouse/warehouse-out";
import WarehouseIn from "../../containers/modules/warehouse/warehouse-in";
import Bank from "../../containers/modules/accounting/bank";
import PrintReceiving from "../Print/default/Receiving";
import Configuration from "../../containers/modules/accounting/configuration";
import Mutation from "../../containers/modules/mutation/mutation";
import Journal from "../../containers/modules/accounting/journal";
import JournalDetail from "../Accounting/journal/detail";
import PrintJournal from "../Print/default/Journal";
import PrintPr from "../Print/default/Pr";
import CashBank from "../../containers/modules/accounting/cash-bank";
import PrintWHOut from "../Print/default/Warehouse-Out";
import PrintWHIn from "../Print/default/Warehouse-In";
import PurchaseReturn from "../../containers/modules/purchasing/purchase-return";
import MemoOut from "../../containers/modules/accounting/memo-out";
import MemoIn from "../../containers/modules/accounting/memo-in";
import PrintMemoOut from "../Print/default/Memo-Out";
import PrintMemoIn from "../Print/default/Memo-In";
import CustomerHierarchy from "../../containers/modules/master/customer-hierarchy";
import SetupHierarchy from "../../containers/modules/master/setup-hierarchy";
import Menu from "../../containers/modules/user/menu";
import Assignment from "../User/assignment";
import Admin from "../../containers/modules/user/admin";
import SalesOrder from "../../containers/modules/sales/sales-order";
import Selling from "../../containers/modules/sales/selling";
import LogPrint from "../../containers/modules/log/print";
import CustomerApproval from "../../containers/modules/log/customer-approval";
import PrintInvoice from "../Print/default/Invoice";
import SellingPrice from "../../containers/modules/sales/selling-price";
import TransactionUpdate from "../../containers/modules/log/transaction-update";
import Division from "../../containers/modules/hr/division";
import Position from "../../containers/modules/hr/position";
import Category from "../../containers/modules/hr/category";
import SalaryType from "../../containers/modules/hr/salary-type";
import Allowance from "../../containers/modules/hr/allowance";
import AllowanceDetail from "../../containers/modules/hr/allowance-detail";
import SalaryCuts from "../../containers/modules/hr/salary-cuts";
import SalaryCutsDetail from "../../containers/modules/hr/salary-cuts-detail";
import SalaryClass from "../../containers/modules/hr/salary-class";
import Attendance from "../../containers/modules/hr/attendance";
import Holiday from "../../containers/modules/hr/holiday";
import Employee from "../../containers/modules/hr/employee";
import ProgramType from "../../containers/modules/marketing/program-type";
import Marketing from "../../containers/modules/marketing/marketing";
import DailyAttendance from "../../containers/modules/hr/daily-attendance";
import LogActivityContainer from "../../containers/modules/log/activity";
import PaymentSelling from "../../containers/modules/sales/payment-selling";
import Approval from "../../containers/modules/user/approval";
import LogApprovalContainer from "../../containers/modules/log/approval";
import PrintSelling from "../Print/default/Selling";
import NewsEvent from "../../containers/modules/news/news-event";
import PrintNewsEvent from "../Print/default/News-Event";
import AccountPayable from "../../containers/modules/report/account-payable";
import UncollectedDebt from "../../containers/modules/report/uncollected-debt";
import AccountReceiveable from "../../containers/modules/report/account-receiveable";
import WorkCalendar from "../../containers/modules/master/work-calendar";
import RouteContainer from "../../containers/modules/user/route";
import Permission from "../User/permission";
import Role from "../User/role";

import CashBankBook from "../../containers/modules/report/cash-bank";
import AccessControl from "../../components/utilities/Class/AccessControl";
import PurchasingReport from "../../containers/modules/report/purchasing-report";
import VisitScheduleContainer from "../../containers/modules/sales/visit-schedule";

import SalesInvoiceReport from "../../containers/modules/report/sales-report";
import TaskContainer from "../../containers/modules/sales/task";
import BillingContainer from "../../containers/modules/sales/billing";
import ItemBrand from "../../containers/modules/master/item-brand";
import ItemCategory from "../../containers/modules/master/item-category";
import Inventory from "../../containers/modules/master/inventory";
import DeliveryOrder from "../../containers/modules/sales/delivery-order";
import Quotation from "../../containers/modules/purchasing/quotation";
import GeneralLedger from "../../containers/modules/report/general-ledger";
import WorkSheet from "../../containers/modules/report/work-sheet";
import TakingGoods from "../../containers/modules/warehouse/taking-goods";
import ReturnPrice from "../../containers/modules/sales/return-price";
import ReturnSelling from "../../containers/modules/sales/return-selling";
import PrintStockCard from "../Print/default/Stock-Card";
import LogBg from "../../containers/modules/accounting/log-bg";
import DownPayment from "../../containers/modules/accounting/down-payment";
import IncomeStatement from "../../containers/modules/report/income-statement";
import BalanceSheet from "../../containers/modules/report/balance-sheet";
import CashFlow from "../../containers/modules/report/cash-flow";
import ConfigBranch from "../../containers/modules/master/config-branch"
import SalesPerformance from "../../containers/modules/chart/sales-performance";
import PrintCashFLow from "../Print/default/Cash-Flow";
import SalesTarget from "../../containers/modules/sales/sales-target";
import PrintBalanceSheet from "../Print/default/Balance-Sheet";
import PrintIncomeStatement from "../Print/default/Income-Statement";
import PrintGeneralLedger from "../Print/default/General-Ledger";
import BeginningBalance from "../../containers/modules/accounting/beginning-balance";
import AssetType from "../../containers/modules/accounting/asset-type";
import Asset from "../../containers/modules/accounting/asset";
import AssetReport from "../../containers/modules/report/asset-report";
import CustomerPerformance from "../../containers/modules/chart/customer-performance";
import ListVisitContainer from "../../containers/modules/sales/visit-schedule/list";
import CashSalesContainer from "../../containers/modules/sales/cash-sales";
import CustomerReport from "../../containers/modules/report/customer-report";
import ResetContainer from "../../containers/modules/auth/ResetPassword";
import NewPassword from "../../containers/modules/auth/NewPassword";
import PrintWorkSheet from "../Print/default/Work-Sheet";
import LoadingFullScreen from "../../components/layouts/LoadingFullScreen";
import AuthActions from "../../reducers/auth/actions";
import InfoDistribution from "../../containers/modules/master/info-distribution";
import ListPayment from "../../containers/modules/accounting/list-payment";
import ReportVisitContainer from "../../containers/modules/sales/report-visit";
import MutationDeposit from "../../containers/modules/mutation/mutation-deposit";
import stockCard from "../../containers/modules/mutation/stock-card";
import settlementSales from "../../containers/modules/mutation/settlement-sales";
import SellingViewContainer from "../../containers/modules/sales/selling/view";
import ViewItem from "../Master/item/view";
import ViewSelling from "../Master/selling/view";
import TrackingPurchasing from "../../containers/modules/report/tracking-purchasing";
import recapAttendance from "../../containers/modules/hr/recap-attendance";
import PrintTakingGoods from "../Print/default/Taking-Goods";
import Download from "../../components/modules/user/download";
const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.isAuthenticated,
  authUser: auth.authUser,
  onVerify: auth.onVerify,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      checkLogin: () => AuthMiddleware.checkLogin(),
      onVerifySet: (data) => AuthActions.onVerifySet(data),
    },
    dispatch
  );
};

export function App(props){
  // const authUser={}
  const {authUser,isAuthenticated,onVerify}=props
  let location = useLocation();

  useEffect(()=>{
    let path=location.pathname
    let split=path.split('/')
    if(split[1] != 'new-password'){
      props.checkLogin()
    }else{
      props.onVerifySet(false)
    }
    
    
  },[])
  return (
    <>
        {onVerify&&<LoadingFullScreen></LoadingFullScreen>}
        {!onVerify&&  
        <Routes>
        <Route path="logout" element={<LogoutContainer />} />
        <Route
          path="purchasing/purchase-order/print/:id"
          element={<PrintPo />}
        />
        <Route
          path="warehouse/taking-goods/print/:id"
          element={<PrintTakingGoods />}
        />
        <Route
          path="mutation/mutation/print/:branch/:brand/:category/:warehouse/:startDate/:endDate"
          element={<PrintStockCard />}
        />  
        <Route 
          path="purchasing/Invoice/print/:id" 
          element={<PrintInvoice />} />
        <Route
          path="purchasing/purchase-request/print/:id"
          element={<PrintPr />}
        />
        <Route
          path="warehouse/warehouse-out/print/:id"
          element={<PrintWHOut />}
        />
        <Route
          path="warehouse/warehouse-in/print/:id"
          element={<PrintWHIn />}
        />
        <Route
          path="accounting/cash-flow/print/:branch/:month/:year"
          element={<PrintCashFLow />}
        />
        <Route
          path="accounting/balance-sheet/print/:branch/:month/:year"
          element={<PrintBalanceSheet />}
        />
        <Route
          path="accounting/income-statement/print/:branch/:month/:year"
          element={<PrintIncomeStatement />}
        />
        <Route
          path="accounting/work-sheet/print/:branch/:startDate/:endDate"
          element={<PrintWorkSheet />}
        />
         <Route
          path="accounting/general-ledger/print/:branch/:startDate/:endDate/:coa3"
          element={<PrintGeneralLedger />}
        />
        <Route
          path="purchasing/receiving/print/:id"
          element={<PrintReceiving />}
        />
        <Route 
          path="sales/selling/print/:id" 
          element={<PrintSelling />} 
        />
        <Route
          path="report/acceptance-report/print/:id"
          element={<PrintNewsEvent />}
        />
        <Route path="accounting/journal/print/:id" element={<PrintJournal />} />
        <Route
          path="accounting/memo-out/print/:id"
          element={<PrintMemoOut />}
        />
        <Route path="accounting/memo-in/print/:id" element={<PrintMemoIn />} />
        <Route element={<LayoutFull></LayoutFull>} errorElement={<Page404 />}>
          <Route path="*" element={<Page404 />} />
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="log">
            <Route path="print" element={<LogPrint />}></Route>
          </Route>
          <Route path="log">
            <Route
              path="customer-approval"
              element={<CustomerApproval />}
            ></Route>
            <Route
              path="transaction-update"
              element={<TransactionUpdate />}
            ></Route>
            <Route path="activity" element={<LogActivityContainer />}></Route>
            <Route path="approval" element={<LogApprovalContainer />}></Route>
          </Route>
          <Route path="/mutation">
            <Route
              index
              element={
                <AccessControl
                  name={"Mutation List"}
                  auth={authUser}
                  component={Mutation}
                />
              }
            />
          </Route>
          <Route path="/stock-card">
            <Route
              index
              element={
                <AccessControl
                  name={"Stock Card List"}
                  auth={authUser}
                  component={stockCard}
                />
              }
            />
          </Route>
          <Route path="/settlement-sales">
            <Route
              index
              element={
                <AccessControl
                  name={"Settlement Sales List"}
                  auth={authUser}
                  component={settlementSales}
                />
              }
            />
          </Route>
          <Route path="/mutation-deposit">
            <Route
              index
              element={
                <AccessControl
                  name={"Mutation Deposit List"}
                  auth={authUser}
                  component={()=><MutationDeposit authUser={authUser}></MutationDeposit>}
                />
              }
            />
          </Route>
          <Route path="/user/download">
              <Route
                index
                element={
                  <Download></Download>
                }
                key={"Unit List"}
              />
          </Route>
          <Route path="/master">
            <Route path="unit">
              <Route
                index
                element={
                  <AccessControl
                    name={"Unit List"}
                    auth={authUser}
                    component={()=><Unit authUser={authUser} />}
                  />
                }
                key={"Unit List"}
              />
            </Route>
            <Route path="info-distribution">
              <Route
                index
                element={
                  <AccessControl
                    name={"Info Distribution List"}
                    auth={authUser}
                    component={()=><InfoDistribution authUser={authUser} />}
                  />
                }
                key={"Unit List"}
              />
              </Route>
            <Route path="item-type">
              <Route
                index
                element={
                  <AccessControl
                    name={"Item Type List"}
                    auth={authUser}
                    component={()=><ItemType authUser={authUser}></ItemType>}
                  />
                }
              />
            </Route>
            {/* <Route path="inventory">
              <Route
                index
                element={
                  <AccessControl
                    name={"Inventory List"}
                    auth={authUser}
                    component={()=><Inventory authUser={authUser}></Inventory>}
                  />
                }
              />
            </Route> */}
            <Route path="item-brand">
              <Route
                index
                element={
                  <AccessControl
                    name={"Item Brand List"}
                    auth={authUser}
                    component={()=><ItemBrand authUser={authUser}></ItemBrand>}
                  />
                }
              />
            </Route>
            <Route path="item-category">
              <Route
                index
                element={
                  <AccessControl
                    name={"Item Category List"}
                    auth={authUser}
                    component={()=><ItemCategory authUser={authUser}></ItemCategory>}
                  />
                }
              />
            </Route>
            <Route path="customer">
              <Route
                index
                element={
                  <AccessControl
                    name={"Customer List"}
                    auth={authUser}
                    component={()=><Customer authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="customer-hierarchy">
              <Route
                index
                element={
                  <AccessControl
                    name={"Customer Hierarchy List"}
                    auth={authUser}
                    component={()=><CustomerHierarchy authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="customer-type">
              <Route
                index
                element={
                  <AccessControl
                    name={"Customer Type List"}
                    auth={authUser}
                    component={()=><CustomerType authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="setup-hierarchy">
              <Route
                index
                element={
                  <AccessControl
                    name={"Customer Setup Hierarchy List"}
                    auth={authUser}
                    component={()=><SetupHierarchy authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="store">
              <Route
                index
                element={
                  <AccessControl
                    name={"Store List"}
                    auth={authUser}
                    component={()=><Store authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="province">
              <Route
                index
                element={
                  <AccessControl
                    name={"Province List"}
                    auth={authUser}
                    component={Province}
                  />
                }
              />
            </Route>
            <Route path="city">
              <Route
                index
                element={
                  <AccessControl
                    name={"City List"}
                    auth={authUser}
                    component={City}
                  />
                }
              />
            </Route>
            <Route path="district">
              <Route
                index
                element={
                  <AccessControl
                    name={"District List"}
                    auth={authUser}
                    component={District}
                  />
                }
              />
            </Route>
            <Route path="subdistrict">
              <Route
                index
                element={
                  <AccessControl
                    name={"Sub District List"}
                    auth={authUser}
                    component={SubDistrict}
                  />
                }
              />
            </Route>
            <Route path="postalcode">
              <Route
                index
                element={
                  <AccessControl
                    name={"Postal Code List"}
                    auth={authUser}
                    component={Postalcode}
                  />
                }
              />
            </Route>
            <Route path="branch">
              <Route
                index
                element={
                  <AccessControl
                    name={"Branch List"}
                    auth={authUser}
                    component={()=><Branch authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="config-branch">
              <Route
                index
                element={
                  <AccessControl
                    name={"Config Branch List"}
                    auth={authUser}
                    component={()=><ConfigBranch authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="warehouse">
              <Route
                index
                element={
                  <AccessControl
                    name={"Warehouse List"}
                    auth={authUser}
                    component={()=><Warehouse authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="sub-warehouse" action={() => this.onRouteChanged()}>
              <Route
                index
                element={
                  <AccessControl
                    name={"Sub Warehouse List"}
                    auth={authUser}
                    component={()=><Subwarehouse authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="supplier">
              <Route
                index
                element={
                  <AccessControl
                    name={"Supplier List"}
                    auth={authUser}
                    component={()=><Supplier authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="vehicle">
              <Route
                index
                element={
                  <AccessControl
                    name={"Vehicle List"}
                    auth={authUser}
                    component={Vehicle}
                  />
                }
              />
            </Route>
            <Route path="tax">
              <Route
                index
                element={
                  <AccessControl
                    name={"Tax List"}
                    auth={authUser}
                    component={()=><Tax authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="setup-warehouse" action={() => this.onRouteChanged()}>
              <Route
                index
                element={
                  <AccessControl
                    name={"Setup Warehouse List"}
                    auth={authUser}
                    component={()=><SetupWarehouse authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="work-calendar">
              <Route
                index
                element={
                  <AccessControl
                    name={"Work Calendar List"}
                    auth={authUser}
                    component={()=><WorkCalendar authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route path="item">
              <Route
                index
                element={
                  <AccessControl
                    name={"Item List"}
                    auth={authUser}
                    component={()=><Item authUser={authUser} />}
                  />
                }
              />
            </Route>
            {/* <Route
              path="/master/item/view/:id"
              element={<ViewItem />}
            /> */}
            <Route
                path="/master/item/view/:id"
                element={
                  <AccessControl
                    name={"Item Show"}
                    auth={authUser}
                    component={() => <ViewItem />}
                  />
                }
              />
          </Route>

          <Route path="/hr">
            <Route path="division">
              <Route
                index
                element={
                  <AccessControl
                    name={"Division List"}
                    auth={authUser}
                    component={Division}
                  />
                }
              />
            </Route>
            <Route path="position">
              <Route
                index
                element={
                  <AccessControl
                    name={"Position List"}
                    auth={authUser}
                    component={Position}
                  />
                }
              />
            </Route>
            <Route path="category-employee">
              <Route
                index
                element={
                  <AccessControl
                    name={"Category List"}
                    auth={authUser}
                    component={Category}
                  />
                }
              />
            </Route>
            <Route path="salary-type">
              <Route
                index
                element={
                  <AccessControl
                    name={"Salary Type List"}
                    auth={authUser}
                    component={SalaryType}
                  />
                }
              />
            </Route>
            <Route path="allowance">
              <Route
                index
                element={
                  <AccessControl
                    name={"Allowance List"}
                    auth={authUser}
                    component={Allowance}
                  />
                }
              />
            </Route>
            <Route path="allowance-detail">
              <Route
                index
                element={
                  <AccessControl
                    name={"Allowance Detail List"}
                    auth={authUser}
                    component={AllowanceDetail}
                  />
                }
              />
            </Route>
            <Route path="salary-cuts">
              <Route
                index
                element={
                  <AccessControl
                    name={"Salary Cuts List"}
                    auth={authUser}
                    component={SalaryCuts}
                  />
                }
              />
            </Route>
            <Route path="salary-cuts-detail">
              <Route
                index
                element={
                  <AccessControl
                    name={"Salary Cuts Detail List"}
                    auth={authUser}
                    component={SalaryCutsDetail}
                  />
                }
              />
            </Route>
            <Route path="salary-class">
              <Route
                index
                element={
                  <AccessControl
                    name={"Salary Class List"}
                    auth={authUser}
                    component={SalaryClass}
                  />
                }
              />
            </Route>
            <Route path="attendance">
              <Route
                index
                element={
                  <AccessControl
                    name={"Attendance List"}
                    auth={authUser}
                    component={Attendance}
                  />
                }
              />
            </Route>
            <Route path="holiday">
              <Route
                index
                element={
                  <AccessControl
                    name={"Holiday List"}
                    auth={authUser}
                    component={Holiday}
                  />
                }
              />
            </Route>
            <Route path="employee">
              <Route
                index
                element={
                  <AccessControl
                    name={"Employee List"}
                    auth={authUser}
                    component={Employee}
                  />
                }
              />
            </Route>
            <Route path="daily-attendance">
              <Route
                index
                element={
                  <AccessControl
                    name={"Daily Attendance List"}
                    auth={authUser}
                    component={DailyAttendance}
                  />
                }
              />
            </Route>
            <Route path="recap-attendance">
              <Route
                index
                element={
                  <AccessControl
                    name={"Recap Daily Attendance List"}
                    auth={authUser}
                    component={recapAttendance}
                  />
                }
              />
            </Route>
          </Route>
          <Route path="/user">
            <Route path="approval">
              <Route
                index
                element={
                  <AccessControl
                    name={"User Approval List"}
                    auth={authUser}
                    component={Approval}
                  />
                }
              />
            </Route>

            <Route path="admin">
              <Route
                index
                element={
                  <AccessControl
                    name={"User List"}
                    auth={authUser}
                    component={Admin}
                  />
                }
              />
              <Route
                path="assignment/:id"
                element={
                  <AccessControl
                    name={"Assignment Show"}
                    auth={authUser}
                    component={Assignment}
                  />
                }
              />
              <Route
                path="route"
                element={
                  <AccessControl
                    name={"Route Show"}
                    auth={authUser}
                    component={RouteContainer}
                  />
                }
              />
              <Route path="permission">
                <Route
                  index
                  element={
                    <AccessControl
                      name={"Permission List"}
                      auth={authUser}
                      component={() => <Permission typeUrl={"index"} />}
                    />
                  }
                />
                <Route
                  path="create"
                  element={
                    <AccessControl
                      name={"Permission Create"}
                      auth={authUser}
                      component={() => <Permission typeUrl={"create"} />}
                    />
                  }
                />
                <Route
                  path="update/:id"
                  element={
                    <AccessControl
                      name={"Permission Update"}
                      auth={authUser}
                      component={() => <Permission typeUrl={"update"} />}
                    />
                  }
                />
                <Route
                  path="assign/:id"
                  element={
                    <AccessControl
                      name={"Permission Assign"}
                      auth={authUser}
                      component={() => <Permission typeUrl={"assign"} />}
                    />
                  }
                />
              </Route>
              <Route path="role">
                <Route
                  index
                  element={
                    <AccessControl
                      name={"Role List"}
                      auth={authUser}
                      component={() => <Role typeUrl={"index"} />}
                    />
                  }
                />
                <Route
                  path="create"
                  element={
                    <AccessControl
                      name={"Role Create"}
                      auth={authUser}
                      component={() => <Role typeUrl={"create"} />}
                    />
                  }
                />
                <Route
                  path="update/:id"
                  element={
                    <AccessControl
                      name={"Role Update"}
                      auth={authUser}
                      component={() => <Role typeUrl={"update"} />}
                    />
                  }
                />
                <Route
                  path="assign/:id"
                  element={
                    <AccessControl
                      name={"Role Assign"}
                      auth={authUser}
                      component={() => <Role typeUrl={"assign"} />}
                    />
                  }
                />
              </Route>
              <Route path="menu">
                <Route
                  index
                  element={
                    <AccessControl
                      name={"Route Show"}
                      auth={authUser}
                      component={() => <Menu />}
                    />
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route path="/purchasing">
            <Route path="offering">
              <Route
                index
                element={
                  <AccessControl
                    name={"Offering List"}
                    auth={authUser}
                    component={() => <Offering  auth={authUser}/>}
                  />
                }
              />
            </Route>
            <Route path="purchase-request">
              <Route
                index
                element={
                  <AccessControl
                    name={"Purchase Request List"}
                    auth={authUser}
                    component={() => <PurchaseRequest />}
                  />
                }
              />
            </Route>
            <Route path="quotation">
              <Route
                index
                element={
                  <AccessControl
                    name={"Quotation Request List"}
                    auth={authUser}
                    component={() => <Quotation />}
                  />
                }
              />
            </Route>
            <Route path="purchase-recap">
              <Route
                index
                element={
                  <AccessControl
                    name={"Purchase Recap Request List"}
                    auth={authUser}
                    component={() => <PurchaseRecap />}
                  />
                }
              />
            </Route>
            <Route path="purchase-order">
              <Route
                index
                element={
                  <AccessControl
                    name={"Purchase Order List"}
                    auth={authUser}
                    component={() => <PurchaseOrder />}
                  />
                }
              />
            </Route>
            <Route path="receiving">
              <Route
                index
                element={
                  <AccessControl
                    name={"Receiving List"}
                    auth={authUser}
                    component={() => <Receiving />}
                  />
                }
              />
            </Route>
            <Route path="invoice">
              <Route
                index
                element={
                  <AccessControl
                    name={"Invoice List"}
                    auth={authUser}
                    component={() => <Invoice />}
                  />
                }
              />
            </Route>
            <Route path="payment">
              <Route
                index
                element={
                  <AccessControl
                    name={"Payment List"}
                    auth={authUser}
                    component={() => <Payment />}
                  />
                }
              />
            </Route>
            <Route path="purchase-return">
              <Route
                index
                element={
                  <AccessControl
                    name={"Purchase Return List"}
                    auth={authUser}
                    component={() => <PurchaseReturn />}
                  />
                }
              />
            </Route>
          </Route>
          <Route path="/accounting">
            <Route path="bank">
              <Route
                index
                element={
                  <AccessControl
                    name={"Bank List"}
                    auth={authUser}
                    component={() => <Bank />}
                  />
                }
              />
            </Route>
            <Route path="list-payment">
              <Route
                index
                element={
                  <AccessControl
                    name={"List Payment"}
                    auth={authUser}
                    component={() => <ListPayment />}
                  />
                }
              />
            </Route>
            <Route path="coa1">
              <Route
                index
                element={
                  <AccessControl
                    name={"Coa1 List"}
                    auth={authUser}
                    component={() => <Coa1 />}
                  />
                }
              />
            </Route>
            <Route path="coa2">
              <Route
                index
                element={
                  <AccessControl
                    name={"Coa2 List"}
                    auth={authUser}
                    component={() => <Coa2 />}
                  />
                }
              />
            </Route>
            <Route path="coa3">
              <Route
                index
                element={
                  <AccessControl
                    name={"Coa3 List"}
                    auth={authUser}
                    component={() => <Coa3 />}
                  />
                }
              />
            </Route>
            <Route path="coa4">
              <Route
                index
                element={
                  <AccessControl
                    name={"Coa4 List"}
                    auth={authUser}
                    component={() => <Coa4 />}
                  />
                }
              />
            </Route>
            <Route path="coa5">
              <Route
                index
                element={
                  <AccessControl
                    name={"Coa5 List"}
                    auth={authUser}
                    component={() => <Coa5 />}
                  />
                }
              />
            </Route>
            <Route path="beginning-balance">
              <Route
                index
                element={
                  <AccessControl
                    name={"Beginning Balance List"}
                    auth={authUser}
                    component={() => <BeginningBalance />}
                  />
                }
              />
            </Route>
            <Route path="accounting-setup">
              <Route
                index
                element={
                  <AccessControl
                    name={"Acc Setup List"}
                    auth={authUser}
                    component={() => <AccSetup />}
                  />
                }
              />
            </Route>
            <Route path="configuration">
              <Route
                index
                element={
                  <AccessControl
                    name={"Acc Config List"}
                    auth={authUser}
                    component={() => <Configuration />}
                  />
                }
              />
            </Route>
            <Route path="journal">
              <Route
                index
                element={
                  <AccessControl
                    name={"Journal List"}
                    auth={authUser}
                    component={()=><Journal authUser={authUser} />}
                  />
                }
              />
              <Route
                path="detail/:id"
                element={
                  <AccessControl
                    name={"Journal Detail List"}
                    auth={authUser}
                    component={() => <JournalDetail />}
                  />
                }
              />
            </Route>
            <Route path="cash-bank">
              <Route
                index
                element={
                  <AccessControl
                    name={"Cash Bank Create"}
                    auth={authUser}
                    component={() => <CashBank />}
                  />
                }
              />
            </Route>
            <Route path="log-bg">
              <Route
                index
                element={
                  <AccessControl
                    name={"Log BG List"}
                    auth={authUser}
                    component={() => <LogBg />}
                  />
                }
              />
            </Route>
            <Route path="down-payment">
              <Route
                index
                element={
                  <AccessControl
                    name={"Down Payment List"}
                    auth={authUser}
                    component={() => <DownPayment />}
                  />
                }
              />
            </Route>
            <Route path="asset-type">
              <Route
                index
                element={
                  <AccessControl
                    name={"Asset Type List"}
                    auth={authUser}
                    component={() => <AssetType />}
                  />
                }
              />
            </Route>
            <Route path="asset">
              <Route
                index
                element={
                  <AccessControl
                    name={"Asset List"}
                    auth={authUser}
                    component={() => <Asset />}
                  />
                }
              />
            </Route>
            <Route path="memo-out">
              <Route
                index
                element={
                  <AccessControl
                    name={"Memo Out Create"}
                    auth={authUser}
                    component={() => <MemoOut />}
                  />
                }
              />
            </Route>
            <Route path="memo-in">
              <Route
                index
                element={
                  <AccessControl
                    name={"Memo In Create"}
                    auth={authUser}
                    component={() => <MemoIn />}
                  />
                }
              />
            </Route>
          </Route>
          <Route path="/warehouse">
            <Route path="warehouse-out">
              <Route
                index
                element={
                  <AccessControl
                    name={"Warehouse Out List"}
                    auth={authUser}
                    component={() => <WarehouseOut />}
                  />
                }
              />
            </Route>
            <Route path="taking-goods">
              <Route
                index
                element={
                  <AccessControl
                    name={"Taking Goods List"}
                    auth={authUser}
                    component={() => <TakingGoods  authUser={authUser}/>}
                  />
                }
              />
            </Route>
            <Route path="warehouse-in">
              <Route
                index
                element={
                  <AccessControl
                    name={"Warehouse In List"}
                    auth={authUser}
                    component={() => <WarehouseIn />}
                  />
                }
              />
            </Route>
          </Route>
          <Route path="/sales">

          <Route path="task">
              <Route
                index
                element={
                  <AccessControl
                    name={"Task List"}
                    auth={authUser}
                    component={()=><TaskContainer />}
                  />
                }
              />
            </Route>
            <Route path="visit-schedule">
              <Route
                index
                element={
                  <AccessControl
                    name={"Visit Schedule Show"}
                    auth={authUser}
                    component={()=><VisitScheduleContainer />}
                  />
                }
              />
            </Route>
            <Route path="list-schedule">
              <Route
                index
                element={
                  <AccessControl
                    name={"Visit Schedule Show"}
                    auth={authUser}
                    component={()=><ListVisitContainer />}
                  />
                }
              />
            </Route>
            <Route path="customer-temp">
              <Route
                index
                element={
                  <AccessControl
                    name={"Temperory Customer List"}
                    auth={authUser}
                    component={() => <TempCustomer />}
                  />
                }
              />
            </Route>
            <Route path="sales-order">
              <Route
                index
                element={
                  <AccessControl
                    name={"Sales Order List"}
                    auth={authUser}
                    component={() => <SalesOrder />}
                  />
                }
              />
            </Route>
            <Route path="sales-target">
              <Route
                index
                element={
                  <AccessControl
                    name={"Sales Target List"}
                    auth={authUser}
                    component={() => <SalesTarget />}
                  />
                }
              />
            </Route>
            <Route path="delivery-order">
              <Route
                index
                element={
                  <AccessControl
                    name={"Delivery Order List"}
                    auth={authUser}
                    component={() => <DeliveryOrder />}
                  />
                }
              />
            </Route>
            <Route path="sales-invoice">
              <Route
                index
                element={
                  <AccessControl
                    name={"Selling List"}
                    auth={authUser}
                    // component={() => <Selling />}
                    component={()=><Selling authUser={authUser} />}
                  />
                }
              />
            </Route>
            <Route
                index
                path="/sales/sales-invoice/view/:id"
                element={
                  <AccessControl
                    name={"Selling Show"}
                    auth={authUser}
                    component={() => <ViewSelling />}
                    // component={() => <SellingViewContainer  /> }
                  />
                }
              />
            <Route path="selling-price">
              <Route
                index
                element={
                  <AccessControl
                    name={"Selling Price List"}
                    auth={authUser}
                    component={() => <SellingPrice authUser={authUser}/>}
                  />
                }
              />
            </Route>
            <Route path="return-price">
              <Route
                index
                element={
                  <AccessControl
                    name={"Return Price List"}
                    auth={authUser}
                    component={() => <ReturnPrice />}
                  />
                }
              />
            </Route>
            <Route path="return-selling">
              <Route
                index
                element={
                  <AccessControl
                    name={"Return Selling List"}
                    auth={authUser}
                    component={() => <ReturnSelling />}
                  />
                }
              />
            </Route>
            <Route path="cash-sales">
              <Route
                index
                element={
                  <AccessControl
                    name={"Cash Sales List"}
                    auth={authUser}
                    component={() => <CashSalesContainer />}
                  />
                }
              />
            </Route>
            <Route path="payment-invoice">
              <Route
                index
                element={
                  <AccessControl
                    name={"Payment Confirmation List"}
                    auth={authUser}
                    component={() => <PaymentSelling />}
                  />
                }
              />
            </Route>
            <Route path="billing">
              <Route
                index
                element={
                  <AccessControl
                    name={"Billing List"}
                    auth={authUser}
                    component={() => <BillingContainer />}
                  />
                }
              />
            </Route>
            <Route path="return-selling">
              <Route
                index
                element={
                  <AccessControl
                    name={"Return Selling List"}
                    auth={authUser}
                    component={() => <ReturnSellingContainer />}
                  />
                }
              />
            </Route>
          </Route>
          <Route path="/marketing">
            <Route path="program-type">
              <Route
                index
                element={
                  <AccessControl
                    name={"Program Type List"}
                    auth={authUser}
                    component={() => <ProgramType />}
                  />
                }
              />
            </Route>
            <Route path="marketing">
              <Route
                index
                element={
                  <AccessControl
                    name={"Marketing List"}
                    auth={authUser}
                    component={() => <Marketing />}
                  />
                }
              />
            </Route>
            
          </Route>
          <Route path="/report">
            <Route path="visit-sales">
              <Route
                index
                element={
                  <AccessControl
                    name={"Report Visit Sales"}
                    auth={authUser}
                    component={() => <ReportVisitContainer />}
                  />
                }
              />
               </Route>
            <Route path="tracking-purchasing">
              <Route
                index
                element={
                  <AccessControl
                    name={"Report Tracking Purchasing"}
                    auth={authUser}
                    component={() => <TrackingPurchasing />}
                  />
                }
              />
               </Route>
            <Route path="acceptance-report">
              <Route
                index
                element={
                  <AccessControl
                    name={"News Event List"}
                    auth={authUser}
                    component={() => <NewsEvent />}
                  />
                }
              />
            </Route>
            <Route path="account-payable">
              <Route
                index
                element={
                  <AccessControl
                    name={"Account Payable Show"}
                    auth={authUser}
                    component={() => <AccountPayable />}
                  />
                }
              />
            </Route>
            <Route path="uncollected-debt">
              <Route
                index
                element={
                  <AccessControl
                    name={"Uncollected Debt Show"}
                    auth={authUser}
                    component={() => <UncollectedDebt />}
                  />
                }
              />
            </Route>
            <Route path="account-receiveable">
              <Route
                index
                element={
                  <AccessControl
                    name={"Account Receiveable Show"}
                    auth={authUser}
                    component={() => <AccountReceiveable />}
                  />
                }
              />
            </Route>
            <Route path="customer-report">
              <Route
                index
                element={
                  <AccessControl
                    name={"Customer Report Show"}
                    auth={authUser}
                    component={() => <CustomerReport />}
                  />
                }
              />  
            </Route>
            <Route path="cash-bank">
              <Route
                index
                element={
                  <AccessControl
                    name={"Cash Bank Book Show"}
                    auth={authUser}
                    component={() => <CashBankBook />}
                  />
                }
              />
            </Route>
            <Route path="general-ledger">
              <Route
                index
                element={
                  <AccessControl
                    name={"General Ledger Show"}
                    auth={authUser}
                    component={() => <GeneralLedger />}
                  />
                }
              />
            </Route>
            <Route path="work-sheet">
              <Route
                index
                element={
                  <AccessControl
                    name={"Work Sheet Show"}
                    auth={authUser}
                    component={() => <WorkSheet />}
                  />
                }
              />
            </Route>
            <Route path="cash-flow">
              <Route
                index
                element={
                  <AccessControl
                    name={"Cash Flow Show"}
                    auth={authUser}
                    component={() => <CashFlow />}
                  />
                }
              />  
            </Route>
            <Route path="income-statement">
              <Route
                index
                element={
                  <AccessControl
                    name={"Income Statement Show"}
                    auth={authUser}
                    component={() => <IncomeStatement />}
                  />
                }
              />  
            </Route>
            <Route path="balance-sheet">
              <Route
                index
                element={
                  <AccessControl
                    name={"Balance Sheet Show"}
                    auth={authUser}
                    component={() => <BalanceSheet />}
                  />
                }
              />  
            </Route>
            <Route path="asset-report">
              <Route
                index
                element={
                  <AccessControl
                    name={"Asset Report Show"}
                    auth={authUser}
                    component={() => <AssetReport />}
                  />
                }
              />  
            </Route>
            <Route path="purchasing-report">
              <Route
                index
                element={
                  <AccessControl
                    name={"Invoice Report Show"}
                    auth={authUser}
                    component={() => <PurchasingReport />}
                  />
                }
              />
            </Route>
            <Route path="sales-report">
              <Route
                index
                element={
                  <AccessControl
                    name={"Sales Invoice Report Show"}
                    auth={authUser}
                    component={() => <SalesInvoiceReport />}
                  />
                }
              />
            </Route>
            <Route path="sales-performance">
              <Route
                index
                element={
                  <AccessControl
                    name={"Sales Performance Chart Show"}
                    auth={authUser}
                    component={() => <SalesPerformance />}
                  />
                }
              />
            </Route>
            <Route path="customer-performance">
              <Route
                index
                element={
                  <AccessControl
                    name={"Customer Performance Show"}
                    auth={authUser}
                    component={() => <CustomerPerformance />}
                  />
                }
              />
            </Route>
          </Route>
        </Route>

        <Route element={<LoginLayout />}>
          <Route path="/login" element={<Login />} errorElement={<Page404 />} />
          <Route path="/reset-password" element={<ResetContainer />} errorElement={<Page404 />} />
        </Route>
        <Route id={"resetpassword"} path="/new-password/:code" element={<NewPassword />} errorElement={<Page404 />} />
      </Routes>
      }
    

    </>
  
    );
  }

export default connect(mapStateToProps, mapDispatchToProps)(App);
