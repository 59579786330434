import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

Font.register({
    family: 'Times-Roman',
    fonts: [
        { src: 'assets/fonts/times.ttf', fontWeight: 400 },
        { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
});
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    logo: {
        width: '50px',
        height: '46.5px',
        padding: '10px',
        border: '1px solid black',
    },
    body: {
        padding: "20px",
    },
    row: {

        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        flexDirection: 'column',
    },
    colBorder: {
        flex: 1,
        border: 1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr: {
        flexDirection: 'row',
    },
    td: {
        flexDirection: 'column',
    },

    //Header
    trHeader: {
        backgroundColor: '#afcace',
        borderBottom: '1px',
        flexDirection: 'row',
    },
    trSign: {
        borderBottom: '1px',
        flexDirection: 'row',
    },
    menuHeader: {
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        fontSize: "8px",
        borderRight: '1px',
        padding: '2px'
    },
    menuSign: {
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        fontSize: "8px",
        borderRight: '1px',
        padding: '5px'
    },
    textFill: {
        textAlign: 'center',
        fontSize: "7px",
        borderRight: '1px',
        borderBottom: '1px',
    },
    titleContainer: {
        flexDirection: 'row',
    },
    header: {
        fontSize: "18px",
        fontFamily: 'Times-Roman'
    },
    address: {
        fontSize: "12px",
        fontFamily: 'Times-Roman'
    },
    titleTranx: {
        fontSize: "10px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center'
    },

    tableTitle: {
        border: '1px solid black',
        fontSize: "8px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        padding: '1px',
    },

    table: {
        fontSize: "8px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        padding: '1px',
    },


    //End Header

    //Table Item
    fillText: {
        textAlign: 'left',
        fontSize: "8px",
        padding: '1px',
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center'
    },

    fillTextColor: {
        textAlign: 'left',
        fontSize: "8px",
        padding: '1px',
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center'
    },

    fillTextLeft: {
        textAlign: 'left',
        fontSize: "8px",
        padding: '5px',
        fontWeight: 700,
        fontFamily: 'Times-Bold',
    },

    //end Table

    //Footer
    footer: {
        position: 'absolute',
        bottom: 0
    },

    breakPage: { pageBreakBefore: "always" },
    //end Footer
});
const tableRowsCount = 11;
class PrintTakingGoods extends Component {
    constructor(props) {
        super(props)
        this.state = {
            itemsPerPage: 15,
            title: i18n.t("BUKTI PENGAMBILAN DAN PENGEMBALIAN BARANG"),
            merchant: {
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address: []
            },
            logo: '/assets/media/logos/logo.jpg',
            data: {
                poNo: '',
                po_detail: []
            }
        };
    }
    renderMessage() {
        const { isProcessing, requestPrint } = this.props
        switch (true) {
            case isProcessing:
                return (
                    <div style={loader}>
                        <div className="spinner-border text-primary" style={{ width: '10rem', height: '10rem' }} role="status"></div><br />
                        <div style={{ fontSize: 36 }}>Getting Data From Server</div>
                    </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{ fontSize: 36 }}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={() => { this.props.getPrint(this.props.poid) }}>Reload Page</Button></div>
                    </div>)
        }
    }

    renderSign(data) {
        let arr = data ?? {};
        return (
            <Fragment>
                <View fixed style={styles.table}  >
                    <View style={styles.trSign} >
                        <Text style={[styles.menuSign, { width: '25%', borderTop: '1px', borderLeft: '1px' }]}>{i18n.t("Diserahkan Oleh,")}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderTop: '1px' }]}>{i18n.t("Diterima Oleh,")}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderTop: '1px' }]}>{i18n.t("Disaksikan Oleh,")}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderTop: '1px' }]}>{i18n.t("Disetujui Oleh,")}</Text>
                    </View>
                    <View style={styles.trSign}>
                        <Text style={[styles.menuSign, { width: '25%', borderRight: '1px', minHeight: '50px', borderLeft: '1px' }]}>{i18n.t("")}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderRight: '1px' }]}>{i18n.t("")}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderRight: '1px' }]}>{i18n.t("")}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderRight: '1px' }]}>{i18n.t("")}</Text>
                    </View>
                    <View style={styles.trSign}>
                        <Text style={[styles.menuSign, { width: '25%', borderRight: '1px', borderLeft: '1px' }]}>{i18n.t(arr.created_by)}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderRight: '1px' }]}>{i18n.t("")}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderRight: '1px' }]}>{i18n.t(arr.name_sales)}</Text>
                        <Text style={[styles.menuSign, { width: '25%', borderRight: '1px' }]}>{i18n.t("")}</Text>
                    </View>
                </View>
            </Fragment>
        );
    }

    renderData(data) {
        let html = [];
        let unit = [3, 1, 4, 5];
        if (data.length > 0) {
            let retur = [];
            let arr = data;
            let convertion = [];
            for (var i = 0; i < this.state.itemsPerPage; i++) {
                console.log(arr[i])
                if (arr[i] != null) {
                    convertion = arr[i].convertion;
                    retur = arr[i].retur;
                    html.push(
                        <View style={styles.tr} >
                            <Text style={[styles.textFill, { width: '5%', borderLeft: '1px' }]} key={"no" + i}>{i + 1}</Text>
                            <Text style={[styles.textFill, { width: '25%', }]} key={"item" + i}>{arr[i].name_item}</Text>
                            {convertion.length > 0 ?
                                unit.map((value, p) => {
                                    const index = convertion.findIndex(convertion => convertion.value.includes(value));
                                    if (index !== -1) {
                                        return (<Text style={[styles.textFill, { width: '7%', }]} key={convertion[index].label + p}>{convertion[index].qty}</Text>);
                                    } else {
                                        return (<Text style={[styles.textFill, { width: '7%', }]} key={"item" + p}>{""}</Text>);
                                    }

                                }) : null}

                            {retur.length > 0 ?
                                unit.map((value, p) => {
                                    const index = retur.findIndex(retur => retur.value.includes(value));
                                    if (index !== -1) {
                                        return (<Text style={[styles.textFill, { width: '7%', }]} key={"item" + p}>{retur[index].qty}</Text>);
                                    } else {
                                        return (<Text style={[styles.textFill, { width: '7%', }]} key={"item" + p}>{""}</Text>);
                                    }

                                }) :
                                unit.map((value, p) => {
                                    return (<Text style={[styles.textFill, { width: '7%', }]} key={"item" + p}>{""}</Text>);
                                })}
                            <Text style={[styles.textFill, { width: '14%', }]} key={"item" + i}>{arr[i].selling}</Text>
                        </View>
                    );
                } else {
                    html.push(
                        <View style={styles.tr}>
                            <Text style={[styles.textFill, { width: '5%', borderLeft: '1px' }]} key={"no" + i}>{i + 1}</Text>
                            <Text style={[styles.textFill, { width: '25%', }]} key={"item" + i}>{""}</Text>
                            {unit.length > 0 ?
                                unit.map((value, p) => {
                                    return (<Text style={[styles.textFill, { width: '7%', }]} key={"item" + p}>{""}</Text>);
                                }) : null}
                            {unit.length > 0 ?
                                unit.map((value, p) => {
                                    return (<Text style={[styles.textFill, { width: '7%', }]} key={"item" + p}>{""}</Text>);
                                }) : null}
                            <Text style={[styles.textFill, { width: '14%', }]} key={"item" + i}>{""}</Text>
                        </View>

                    );
                }

            }
        }

        return html;
    }

    renderMenu(data) {
        let html = [];

        html.push(
            <View fixed style={styles.table}  >
                <View style={styles.trHeader} >
                    <Text style={[styles.menuHeader, { width: '5%', borderTop: '1px', borderLeft: '1px' }]} key={"menu_no"}>{i18n.t("No.")}</Text>
                    <Text style={[styles.menuHeader, { width: '25%', borderTop: '1px' }]}>{i18n.t("JENIS BARANG / MERK")}</Text>
                    <Text style={[styles.menuHeader, { width: '28%', borderTop: '1px' }]}>{i18n.t("DO")}</Text>
                    <Text style={[styles.menuHeader, { width: '28%', borderTop: '1px' }]}>{i18n.t("KEMBALI")}</Text>
                    <Text style={[styles.menuHeader, { width: '14%', borderTop: '1px' }]}>{i18n.t("JUAL")}</Text>
                </View>
                <View style={styles.trHeader} >
                    <Text style={[styles.menuHeader, { width: '5%', borderLeft: '1px' }]}>{i18n.t("")}</Text>
                    <Text style={[styles.menuHeader, { width: '25%' }]}>{i18n.t("")}</Text>
                    <Text style={[styles.menuHeader, { width: '7%' }]}>{i18n.t("BOX")}</Text>
                    <Text style={[styles.menuHeader, { width: '7%' }]}>{i18n.t("BALL")}</Text>
                    <Text style={[styles.menuHeader, { width: '7%' }]}>{i18n.t("SLOP")}</Text>
                    <Text style={[styles.menuHeader, { width: '7%' }]}>{i18n.t("BKS")}</Text>
                    <Text style={[styles.menuHeader, { width: '7%' }]}>{i18n.t("BOX")}</Text>
                    <Text style={[styles.menuHeader, { width: '7%' }]}>{i18n.t("BALL")}</Text>
                    <Text style={[styles.menuHeader, { width: '7%' }]}>{i18n.t("SLOP")}</Text>
                    <Text style={[styles.menuHeader, { width: '7%' }]}>{i18n.t("BKS")}</Text>
                    <Text style={[styles.menuHeader, { width: '14%' }]}>{i18n.t("")}</Text>
                </View>
                <View>
                    {this.renderData(data)}
                </View>
            </View>

        );
        return html;
    }

    render() {
        const { dataPrint } = this.props
        let data = null;
        let itemsPerPage = this.state.itemsPerPage;
        let pages = [];

        if (dataPrint != null) {
            if (dataPrint.model != null) {
                data = dataPrint.model;
                let detail = data.details;
                for (let i = 0; i < detail.length; i += itemsPerPage) {
                    pages.push(detail.slice(i, i + itemsPerPage));
                }
            }
        }
        return (
            <React.Fragment>{(dataPrint != null) ?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title + ((dataPrint != undefined) ? "" : "")}>
                        {pages.map((items, pageIndex) => (
                            <Page wrap size={"A4"} style={styles.page} orientation={"landscape"}>
                                <View style={styles.body}>
                                    <View style={styles.titleContainer}>
                                        <View style={styles.row}>
                                            <View style={[styles.col, { width: '48%' }]} >
                                                <View style={styles.tableTitle}>
                                                    <View style={[styles.tr, { width: '100%' }]}>
                                                        <View style={[styles.td]} >
                                                            <View>
                                                                <Image src={(this.state.merchant.logo != null) ? this.state.merchant.logo : "/assets/media/logos/logo.jpg"} style={styles.logo} />
                                                            </View>
                                                        </View>
                                                        <View style={[styles.td, { width: '88%' }]}>
                                                            <View style={styles.titleTranx}>
                                                                <Text style={{ border: '1px solid black', padding: '5px' }}>{i18n.t("BUKTI PENGAMBILAN DAN PENGEMBALIAN BARANG")}</Text>
                                                            </View>
                                                            <View style={styles.titleTranx}>
                                                                <Text style={{ border: '1px solid black', padding: '5px' }}>{i18n.t(data?.name_branch || '') + ' - ' + i18n.t(data?.name_store || '')} </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={styles.tr}>
                                                        <View style={[styles.td, { width: '25%', border: '1px solid black', backgroundColor :'#c5e3e7' }]} >
                                                            <View style={styles.fillText}>
                                                                <Text>No Formulir : </Text>
                                                                <Text>{data?.transaction_no || ''} </Text>
                                                            </View>
                                                        </View>
                                                        <View style={[styles.td, { width: '25%', border: '1px solid black', backgroundColor :'#c5e3e7'  }]} >
                                                            <View style={styles.fillText}>
                                                                <Text>Revisi : 00</Text>
                                                            </View>
                                                        </View>
                                                        <View style={[styles.td, { width: '25%', border: '1px solid black', backgroundColor :'#c5e3e7'  }]} >
                                                            <View style={styles.fillText}>
                                                                <Text>Tanggal Efektif : </Text>
                                                                <Text>{data?.date_format || ''}</Text>
                                                            </View>
                                                        </View>
                                                        <View style={[styles.td, { width: '25%', border: '1px solid black', backgroundColor :'#c5e3e7'  }]} >
                                                            <View style={styles.fillText}>
                                                                <Text>{"Halaman : " + (pageIndex + 1) + ' dari ' + (pages.length)}</Text>
                                                            </View>
                                                        </View>
                                                    </View>

                                                    <View style={[styles.tr, { 'paddingTop': '5px' }]}></View>

                                                    <View style={styles.tr}>
                                                        <View style={[styles.td, { width: '65%' }]} >
                                                            <View style={styles.fillTextLeft}>
                                                                <Text>Tanggal : {data?.date_format || ''} </Text>
                                                                <Text>Tujuan : </Text>
                                                            </View>
                                                        </View>

                                                        <View style={[styles.td, { width: '15%', border: '1px solid black', backgroundColor :'#c5e3e7' }]} >
                                                            <Text style={[styles.fillTextColor, { borderBottom: '1px' }]}>{i18n.t("No. Doc")}</Text>
                                                            <Text style={[styles.fillTextColor]}>{i18n.t("Lembar")}</Text>
                                                        </View>
                                                        <View style={[styles.td, { width: '20%', border: '1px solid black' }]} >
                                                            <Text style={[styles.fillTextColor, { borderBottom: '1px' }]}>{i18n.t("   ")}</Text>
                                                            <Text style={[styles.fillTextColor]}>{i18n.t("1 (GUDANG)")}</Text>
                                                        </View>
                                                    </View>

                                                    <View style={[styles.tr, { 'paddingTop': '5px' }]}></View>
                                                    <View style={styles.tr}>
                                                        {this.renderMenu(items)}
                                                    </View>

                                                    <View style={[styles.tr, { 'paddingTop': '5px' }]}></View>
                                                    <View style={[styles.tr]}>
                                                        <View style={[styles.td, { width: '100%' }]}>
                                                            <Text style={[styles.titleTranx, { padding: '3px', backgroundColor:'#D3D3D3' }]}>{i18n.t("DO")}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={styles.tr}>
                                                        {this.renderSign(data)}
                                                    </View>
                                                    <View style={[styles.tr]}>
                                                        <View style={[styles.td, { width: '100%' }]}>
                                                            <Text style={[styles.titleTranx, { padding: '3px', backgroundColor:'#D3D3D3' }]}>{i18n.t("KEMBALI")}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={styles.tr}>
                                                        {this.renderSign(data)}
                                                    </View>
                                                </View>
                                            </View>

                                            {/* SEKAT */}
                                            {/* <View style={[styles.col, { width: '1%' }]} >
                                        </View> */}


                                            {/* PENGEMBALIAN    */}
                                            <View style={[styles.col, { width: '48%' }]} >
                                                <View style={styles.tableTitle}>
                                                    <View style={[styles.tr, { width: '100%' }]}>
                                                        <View style={[styles.td]} >
                                                            <View>
                                                                <Image src={(this.state.merchant.logo != null) ? this.state.merchant.logo : "/assets/media/logos/logo.jpg"} style={styles.logo} />
                                                            </View>
                                                        </View>
                                                        <View style={[styles.td, { width: '88%' }]}>
                                                            <View style={styles.titleTranx}>
                                                                <Text style={{ border: '1px solid black', padding: '5px' }}>{i18n.t("BUKTI PENGAMBILAN DAN PENGEMBALIAN BARANG")}</Text>
                                                            </View>
                                                            <View style={styles.titleTranx}>
                                                                <Text style={{ border: '1px solid black', padding: '5px' }}>{i18n.t(data?.name_branch || '') + ' - ' + i18n.t(data?.name_store || '')} </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View style={styles.tr}>
                                                        <View style={[styles.td, { width: '25%', border: '1px solid black', backgroundColor :'#c5e3e7' }]} >
                                                            <View style={styles.fillText}>
                                                                <Text>No Formulir : </Text>
                                                                <Text>{data?.transaction_no || ''} </Text>
                                                            </View>
                                                        </View>
                                                        <View style={[styles.td, { width: '25%', border: '1px solid black', backgroundColor :'#c5e3e7' }]} >
                                                            <View style={styles.fillText}>
                                                                <Text>Revisi : 00</Text>
                                                            </View>
                                                        </View>
                                                        <View style={[styles.td, { width: '25%', border: '1px solid black', backgroundColor :'#c5e3e7' }]} >
                                                            <View style={styles.fillText}>
                                                                <Text>Tanggal Efektif : </Text>
                                                                <Text>{data?.date_format || ''}</Text>
                                                            </View>
                                                        </View>
                                                        <View style={[styles.td, { width: '25%', border: '1px solid black', backgroundColor :'#c5e3e7' }]} >
                                                            <View style={styles.fillText}>
                                                                <Text>{"Halaman : " + (pageIndex + 1) + ' dari ' + (pages.length)}</Text>
                                                            </View>
                                                        </View>
                                                    </View>

                                                    <View style={[styles.tr, { 'paddingTop': '5px' }]}></View>

                                                    <View style={styles.tr}>
                                                        <View style={[styles.td, { width: '65%' }]} >
                                                            <View style={styles.fillTextLeft}>
                                                                <Text>Tanggal : {data?.date_format || ''} </Text>
                                                                <Text>Tujuan : </Text>
                                                            </View>
                                                        </View>

                                                        <View style={[styles.td, { width: '15%', border: '1px solid black', backgroundColor :'#c5e3e7' }]} >
                                                            <Text style={[styles.fillTextColor, { borderBottom: '1px' }]}>{i18n.t("No. Doc")}</Text>
                                                            <Text style={[styles.fillTextColor]}>{i18n.t("Lembar")}</Text>
                                                        </View>
                                                        <View style={[styles.td, { width: '20%', border: '1px solid black' }]} >
                                                            <Text style={[styles.fillTextColor, { borderBottom: '1px' }]}>{i18n.t("   ")}</Text>
                                                            <Text style={[styles.fillTextColor]}>{i18n.t("2 (PENERIMA)")}</Text>
                                                        </View>
                                                    </View>

                                                    <View style={[styles.tr, { 'paddingTop': '5px' }]}></View>
                                                    <View style={styles.tr}>
                                                        {this.renderMenu(items)}
                                                    </View>

                                                    <View style={[styles.tr, { 'paddingTop': '5px' }]}></View>
                                                    <View style={[styles.tr]}>
                                                        <View style={[styles.td, { width: '100%' }]}>
                                                            <Text style={[styles.titleTranx, { padding: '3px', backgroundColor:'#D3D3D3' }]}>{i18n.t("DO")}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={styles.tr}>
                                                        {this.renderSign(data)}
                                                    </View>
                                                    <View style={[styles.tr]}>
                                                        <View style={[styles.td, { width: '100%' }]}>
                                                            <Text style={[styles.titleTranx, { padding: '3px', backgroundColor:'#D3D3D3' }]}>{i18n.t("KEMBALI")}</Text>
                                                        </View>
                                                    </View>
                                                    <View style={styles.tr}>
                                                        {this.renderSign(data)}
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </Page>
                        ))}
                    </Document>
                </PDFViewer>
                :
                this.renderMessage()}

            </React.Fragment>

        )
    }
}

export default PrintTakingGoods;

const loader = {
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}