import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Receiving } from "../../../../components/modules/purchasing/receiving";
import ReceivingMiddleware from "../../../../middleware/modules/purchasing/receiving";
import i18n from "../../../../i18n";
import { Helmet } from "react-helmet";
// middlewares
const mapStateToProps = ({ auth, receiving }) => ({
  isProcessing: receiving.isProcessing,
  isError: receiving.isError,
  errorMessage: receiving.errorMessage,
  datalist: receiving.data,
  showModal: receiving.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => ReceivingMiddleware.create(),
      reset: () => ReceivingMiddleware.resetList(),
      search: (data) => ReceivingMiddleware.list(data),
      update: (data) => ReceivingMiddleware.update(data),
      delete: (id, search) => ReceivingMiddleware.beforeDelete(id, search),
      view: data =>ReceivingMiddleware.getOne(data),
      getHelperFull: (id, search) =>
        ReceivingMiddleware.getHelperFull(id, search),
    },
    dispatch
  );
};
class ReceivingContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.viewItem = this.viewItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }
  viewItem(id){
    this.props.view(id)
    this.props.getHelperFull()
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }
  updateItem(id) {
    this.props.getHelperFull();
    this.props.update(id);
  }
  createItem() {
    this.props.create();
    this.props.getHelperFull();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Goods Receipt View")}
          </title>
        </Helmet>
      <Receiving
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        viewItem={this.viewItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingContainer);
