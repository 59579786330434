import React,{ Fragment, useState } from "react"
import { Button, Table } from "react-bootstrap"
import i18n from "../../../../i18n";
import LabelTranscation from "../../../utilities/Status/transaction";
import { formatCurrency } from "../../../utilities/Class/FormatNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const squareStyle = {
    width: "150px",
    height: "150px",
    border: "3px dotted grey",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
  };
export default function DataListRequest(data){
    let arr = data.dataTracking;
    let click = data.updateShow;
    return (
        <div className="card mb-12 mb-xl-8">	
        {arr.data != null ? 
            arr.data.map((item,i)=>{
            return (
                <Fragment key={item.name_supplier}>
                    <div className="row" >
                       <h1> {(i+1) + '. ' + item.name_supplier}</h1>
                    </div>
                    {item.data_pr != null ? 
                        renderDetail(item.data_pr,click) : null}
                </Fragment>
            )     
                
            })
            :null
            }	
            {/* <div style={squareStyle}>Dotted Square
            
            </div>			 */}

            {/* <div className="card-body pt-5 px-0">
             {arr != null? 
                <React.Fragment>
                <div className="d-flex flex-column text-left mb-9 px-9">
                   <table>
                    <tbody className="text-gray-800 fw-bold fs-5">
                        <tr>
                            <td>
                                {i18n.t('Date')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                {arr.date}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {i18n.t('No. PR')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                {arr.pr_no}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {i18n.t('Status')}
                            </td>
                            <td>
                                :
                            </td>
                            <td>
                                <LabelTranscation value={arr.status}></LabelTranscation>
                            </td>
                        </tr>
                    </tbody>
                   </table>
                </div>
                <div className="d-flex flex-column text-left mb-9 px-9">
                  
                    <div className="text-left">
                    <Table
                    className="table-responsive table-index"
                >
                    <thead className="bg-success text-white text-center" >
                        <tr>
                            <th>{i18n.t("No.")}</th>
                            <th>{i18n.t("Name Item")}</th>
                            <th>{i18n.t("Unit")}</th>
                            <th>{i18n.t("Qty")}</th>
                        </tr>
                    </thead>
                    <tbody>

                        {arr.req_detail?.map((item,index)=>
                        <tr key={'detail'+index} >
                            <td className="text-gray-800 fw-bold fs-5" style={{textAlign:'center'}}>
                                {index+1}
                            </td>
                            <td style={{textAlign:'center'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {item.name_item} </span>
                            </td>
                            <td style={{textAlign:'center'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {item.name_unit} </span>
                            </td>
                            <td style={{textAlign:'right'}}>
                                <span className="text-gray-800 fw-bold fs-5" > {formatCurrency(item.qty,0)} </span>
                            </td>
                          
                        </tr>
                        )}
                    </tbody>
               </Table>
                        
                        
                        
            </div>
                    
                </div>
                </React.Fragment>
                :<div className="text-center"><h2>{i18n.t("Not Have Data About Purchase Request")}</h2></div>}
            </div> */}
            
        </div>
    )

    function renderDetail(data,click){
        let arr = data;
        let html = [];
        html.push(
            <Fragment key={'supplier'}>
            <div className="row">
                <table className="table table-responsive table-index " style={{fontSize:'14px'}}>
                    <thead className="bg-success text-white text-center">
                        <tr className="border text-center">
                            <th width={"3%"} className="text-center">{i18n.t("No")}</th>
                            <th width={"15%"} >{i18n.t("Date")}</th>
                            <th width={"15%"}>{i18n.t("No. Purchase Request")}</th>
                            <th width={"15%"}>{i18n.t("Status")}</th>
                            <th width={"15%"}>{i18n.t("Action")}</th>
                        </tr>
                    </thead>
                    <tbody className="border ">
                        {arr!=null?
                            arr.map((item,s)=>{
                                return(
                                    <tr className="border text-center"  key={'supplier'+s}>
                                        <td style={{padding:'5px', textAlign:'center'}}>{(s+1)}</td>
                                        <td style={{padding:'5px', textAlign:'center'}}>{item.date}</td>
                                        <td style={{padding:'5px', textAlign:'center'}}>{item.no_pr}</td>
                                        <td style={{padding:'5px', textAlign:'center'}}>
                                            <LabelTranscation value={item.status}/>
                                        </td>
                                        <td style={{padding:'5px', textAlign:'center'}}>
                                        <Button
                                            onClick={(e) => {
                                                // this.setState({showData:false,showDataDetail:false});
                                                click(true,item.id);
                                            }}
                                            // disabled={
                                            //   isProcessing
                                            // }
                                            variant="success"
                                            className="btn btn-xs btn btn-success "
                                            title={i18n.t("View")}
                                            >
                                            <span className="indicator-label"><FontAwesomeIcon icon={faEye} ></FontAwesomeIcon> </span>
                                       
                                            
                                            </Button>
                                        </td>
                                    </tr>)
                            })
                        :null}
                    </tbody>
                </table>
            </div>
            </Fragment>
        );

        return html;
    }
}

 

