
import React from "react";
import i18n from "../../../i18n";

const LabelApproveCustomerTemp=(props)=>{
    switch(props.value){
        case 4:
            return <div className="badge badge-light-primary">{i18n.t("Close")}</div>
        case 1:
            return <div className="badge badge-light-warning">{i18n.t("Waiting For Approval")}</div>
        case 0:
            return <div className="badge badge-light-success">{i18n.t("Approved")}</div>
        case 2:
            return <div className="badge badge-light-danger">{i18n.t("Rejected")}</div>
    }
}
export default LabelApproveCustomerTemp;