import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Offering } from "../../../../components/modules/purchasing/offering";
// middlewares
import OfferingMiddleware from "../../../../middleware/modules/purchasing/offering";
import SupplierMiddleware from "../../../../middleware/modules/master/supplier";
import UnitMiddleware from "../../../../middleware/modules/master/unit";
import ItemMiddleware from "../../../../middleware/modules/master/item";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";

const mapStateToProps = ({ auth, offering }) => ({
  authUser:auth.authUser,
  isProcessing: offering.isProcessing,
  isError: offering.isError,
  errorMessage: offering.errorMessage,
  datalist: offering.data,
  showModal: offering.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => OfferingMiddleware.create(),
      search: (data) => OfferingMiddleware.list(data),
      update: (data) => OfferingMiddleware.update(data),
      delete: (id, search) => OfferingMiddleware.beforeDelete(id, search),
      reset: () => OfferingMiddleware.resetList(),
      approve: (id, value, search) => OfferingMiddleware.beforeApprove(id, value, search),
      reject: (id, value, search) => OfferingMiddleware.beforeReject(id, value, search),
      getHelper: () => OfferingMiddleware.getHelperFull(),
      getData: () => OfferingMiddleware.showOne(data),
    },
    dispatch
  );
};
class OfferingContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.approveItem = this.approveItem.bind(this);
    this.rejectItem = this.rejectItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }

  updateItem(id) {
    this.props.getHelper();
    this.props.update(id);
  }
  createItem() {
    this.props.create();
    this.props.getHelper();
  }
  approveItem(id, value, search) {
    this.props.approve(id, value, search);
  }
  rejectItem(id, value, search) {
    this.props.reject(id, value, search);
  }
  render() {
    const { isProcessing, search, datalist, showModal, authUser } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Offering List")}
          </title>
        </Helmet>
      <Offering
        authUser={authUser}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        approveItem={this.approveItem}
        rejectItem={this.rejectItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferingContainer);
