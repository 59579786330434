import { useParams } from "react-router-dom";
import React from "react";
import PoContainer from '../../../containers/Print/default/Po'
import TakingGoods from "../../../containers/Print/default/Taking-Goods";
export const PrintTakingGoods = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <TakingGoods id={id}/>
    )
}

export default PrintTakingGoods;