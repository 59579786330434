import { faBoxArchive, faBoxOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React,{ Component } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import DatePickerCustom from '../../../utilities/Form/datepicker';
import Input from '../../../utilities/Form/input';
import Radio from '../../../utilities/Form/radio';
import Select2 from '../../../utilities/Form/Select2';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import axios from 'axios';
import FormConvertion from './formConvertion';
import InputNumber from '../../../utilities/Form/InputNumber';
import cookieManager from '../../../../utils/cookieManager';
import FormConvertionLive from './formConvertionLive';
import i18n from '../../../../i18n';

export class Form extends Component {
    constructor(props) {
        super(props)
        this.state={
            form:{
                Invoice:{},
                InvoiceDetail:[]
            },
            top:0,
            invoiceNo:'',
            branchCurrent:'',
            branchText:i18n.t('Select Branch'),
            branchList:[],
            storeCurrent:'',
            storeText:i18n.t('Select Store'),
            storeList:[],
            transactionMethodList:{1:i18n.t('Receiving')},
            // transactionMethodList:{1:'Receiving',2:'Purchase Order',3:'Same Time'},
            transactionMethod:1,
            date:new Date(),
            supplierCurrent:null,
            supplierList:[],
            supplierText:i18n.t('Select Supplier'),
            supplierInvoice:'',
            paymentTypeList:{'Cash':i18n.t('Cash/Transfer'),'Credit':i18n.t('Credit')},
            paymentType:'Cash',
            BGDate:new Date(),
            BGAccountList:[],
            BGAccountCurrent:null,
            BGAccountText:i18n.t('Cash/Bank account'),
            dateSupplier:new Date(),
            dueDate:new Date(),
            subTotal:0,
            tax:0,
            pph22:0,
            pph22Status:0,
            receivingCurrent:[],
            receivingList:[],
            receivingText:i18n.t('Select Receiving'),
            poCurrent:[],
            poList:[],
            poText:i18n.t('Select Purchase Order'),
            itemList:[],
            itemText:i18n.t('Select Item'),
            modalDetail:false,
            titleModal:i18n.t('Convertion'),
            itemChoose:null,
            warehouseList:[],
            warehouseText:i18n.t('Select Warehouse'),
            unitText:i18n.t('Select Unit'),
            isUpdate:0,
            grandTotal:0,
            discountHeader:0,
            totalDP:0,
            useDP:false,
            saveDP:false,
            tempDP:0,
            BGNo:'',
            totalRemaining:0,
            checkDownpayment:false,
            arrayDetail:[
                {
                    idPo:'',
                    idReceiving:'',
                    idReceivingDetail:'',
                    idPODetail:'',
                    itemValue:null,
                    description:'',
                    qty:0,
                    unit:[],
                    poValue:null,
                    warehouseValue:null,
                    qtyAvailable:0,
                    itemList:[],
                    bandNo:'',
                    batchNo:'',
                    unitValue:null,
                    expDate:new Date(),
                    suffixqty:'',
                    convertionDetail:[],
                    unitPrice:0,
                    discountPrice:0,
                    discount:0,
                    tax:0,
                    taxPrice:0,
                    nettPrice:0,
                    total:0,
                    poDetail:null
                }
            ],
            
        }
        this.formConvertion=React.createRef(),
        this.handler = this.handler.bind(this)
        this.handlerSelect = this.handlerSelect.bind(this)
        this.handlerDateTime = this.handlerDateTime.bind(this)
        this.runSubmit=this.runSubmit.bind(this)
        this.discountChange=this.discountChange.bind(this)
    }
   
    handler(e) {
        var key=e.target.name;
        var value=e.target.value
        this.setState({
            [key]:value 
        })
    }
    handlerSelect(e,name,currentState) {
        var key=name
        var value=null
        if(e!=null){
            value=e.value
        }
        this.setState({
            ...this.state, 
            [key]:value
        })
        this.setState({[currentState]:e})
    }
    
    calculateRow(i,arrayname){
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        let price= parseFloat(this.state.arrayDetail[i].unitPrice)||0
        let qty=parseFloat(this.state.arrayDetail[i].qty)||0
        let taxValue=parseFloat(this.state.taxValue)||0
        let discount= parseFloat(this.state.arrayDetail[i].discountPrice) || 0
        let priceTax=0;
        if(this.state.tax){
            priceTax= (price- discount) * (taxValue/100)
        }

       
        let priceNett=(price +priceTax - discount)
        let priceTotal=priceNett * qty
        objectss["nettPrice"] =priceNett
        objectss["taxPrice"] =priceTax
        objectss["total"] =priceTotal
        this.setState(arr)
        
        this.calculateAll()
    }

    calculateAll(){
        let subTotal=0
        let grandTotal=0
        let taxTotal=0
        let discountTotal=0
        let dpTotal=0
        let remaining=0
        let arrDP = [];
        let discountInvoice=parseFloat(this.state.discountHeader)||0
        const detail=this.state.arrayDetail
        for(let i=0;i<detail.length;i++){
            let price=parseFloat(detail[i].unitPrice)||0
            let qty=parseFloat(detail[i].qty)||0
            let discount=parseFloat(detail[i].discountPrice)||0
            let tax=parseFloat(detail[i].taxPrice) || 0
            let dp=parseFloat(detail[i].downPayment) || 0
            
            if(i == 0){
                arrDP[i] = detail[i].idDP;
                dpTotal += dp
            }else{
                if(arrDP.includes(detail[i].idDP) == false){
                    arrDP[i] = detail[i].idDP;
                    dpTotal += dp
                }

            }
           
            subTotal +=price * qty
            discountTotal += discount *qty
            taxTotal += tax * qty
            grandTotal += (price - discount+ tax)* qty
            remaining += (price - discount+ tax)* qty
        }
        discountTotal +=discountInvoice;
        grandTotal -= discountInvoice;
        remaining -= discountInvoice
        remaining -= dpTotal;

        this.setState({
            subTotal:subTotal,
            discountTotal:discountTotal,
            taxTotal:taxTotal,
            totalRemaining:(remaining < 0)?0:remaining,
            grandTotal:grandTotal,
            totalDP:dpTotal
        })
    }
    discountChange(e,i,arrayname,sender='Price'){
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        let price=parseFloat(arr[i].unitPrice) || 0
        var value=parseFloat(e)||0
        
        let percent=0
        let valuePrice=0
        if(sender=='Price'){
            percent=(value/price) *100
            valuePrice=value
            if(value > price){
                valuePrice=price
                percent=100
            }
        }
        if(sender=='Percent'){
            percent=value
            valuePrice=price * (value/100)
            if(percent > 100){
                valuePrice=price
                percent=100
            }
            
        }

        objectss['discountPrice'] = valuePrice.toFixed(2);
        objectss['discount'] = percent.toFixed(2);
        this.setState(arr,()=>this.calculateRow(i,arrayname))
    }

    runSubmit(){
        let totalHasil = 0;
        totalHasil = (this.state.totalDP - this.state.grandTotal)
        const Invoice={
            invoiceNo:this.state.invoiceNo,
            idBranch:((this.state.branchCurrent!=null)?this.state.branchCurrent.value:''),
            idStore:((this.state.storeCurrent!=null)?this.state.storeCurrent.value:''),
            transactionMethod:this.state.transactionMethod,
            discountHeader:this.state.discountHeader,
            date:moment(this.state.date).format('YYYY-MM-DD'),
            idSupplier:((this.state.supplierCurrent!=null)?this.state.supplierCurrent.value:''),
            supplierInvNo:this.state.supplierInvoice,
            paymentType:this.state.paymentType,
            // BGDate:(this.state.paymentType=='BG')?moment(this.state.BGDate).format('YYYY-MM-DD'):null,
            // BGNo:(this.state.paymentType=='BG')?this.state.BGNo:null,
            BGAccount:((this.state.BGAccountCurrent!=null)?this.state.BGAccountCurrent.value:''),
            dueDate:moment(this.state.dueDate).format('YYYY-MM-DD'),
            dateSupplier:moment(this.state.dateSupplier).format('YYYY-MM-DD'),
            pph22:this.state.pph22,
            pph22Status:this.state.pph22Status,
            tax:this.state.tax,
            subTotal:this.state.subTotal,
            total:this.state.grandTotal,
            totalPayment:(this.state.paymentType=='CASH')?this.state.total:0,
            downpayment:(totalHasil<0)?this.state.totalDP:this.state.grandTotal,
            remainingPayment:this.state.totalRemaining,
        }
        const detail=this.state.arrayDetail
        let idPO=[]
        let idPODetail=[]
        let idReceiving=[]
        let idReceivingDetail=[]
        let idWarehouse=[]
        let batchNo=[]
        let bandNo=[]
        let expiredDate=[]
        let qty=[]
        let description=[]
        let idUnit=[]
        let idItem=[]
        let total=[]
        let unitPrice=[]
        let nettPrice=[]
        let taxPrice=[]
        let tax=[]
        let discount=[]
        let downPayment=[]
        let idDP=[]
        let discountPrice=[]
        let convertionList=[]

        for(var i=0;i<detail.length;i++){
            idPO.push(detail[i].idPo);
            idReceiving.push(detail[i].idReceiving);
            idReceivingDetail.push(detail[i].idReceivingDetail);
            idPODetail.push(detail[i].idPODetail);
            idItem.push((detail[i].itemValue==null?'':detail[i].itemValue.value))
            idWarehouse.push((detail[i].warehouseValue==null)?'':detail[i].warehouseValue.value);
            batchNo.push(detail[i].batchNo==null?null:detail[i].batchNo);
            bandNo.push(detail[i].bandNo);
            expiredDate.push((detail[i].expiredDate==null?null:moment(detail[i].expiredDate).format('YYYY-MM-DD')));
            qty.push(detail[i].qty);
            unitPrice.push(detail[i].unitPrice);
            discountPrice.push(detail[i].discountPrice);
            discount.push(detail[i].discount);
            tax.push(detail[i].tax);
            taxPrice.push(detail[i].taxPrice);
            nettPrice.push(detail[i].nettPrice);
            total.push(detail[i].total);
            downPayment.push(detail[i].downPayment==null?"":detail[i].downPayment);
            idDP.push(detail[i].idDP==null?"":detail[i].idDP);
            convertionList.push(detail[i].convertionDetail);
            idUnit.push((detail[i].unitValue==null)?'':detail[i].unitValue.value)
        }
        this.state.form.Invoice=Invoice
        this.state.form.InvoiceDetail={
            idPo:idPO,
            idPoDetail:idPODetail,
            idWarehouse:idWarehouse,
            batchNo:batchNo,
            expDate:expiredDate,
            qty:qty,
            description:description,
            bandNo:bandNo,
            convertionDetail:convertionList,
            unitPrice:unitPrice,
            discount:discount,
            discountPrice:discountPrice,
            taxPrice:taxPrice,
            nettPrice:nettPrice,
            idReceiving:idReceiving,
            idReceivingDetail:idReceivingDetail,
            idItem:idItem,
            idUnit:idUnit,
            downPayment:downPayment,
            idDP:idDP,
        }
        return this.state.form
    }

    handlerSelectArray(e,name,i,arrayname) {
        
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        if(e==null){
            // objectss.value = null;
            objectss[name] = null;
        }else{
            // var value=e.value
            // objectss.value = e;
            objectss[name] = e;
        }
        this.setState(arr)
    }
    handlerCurrencyArray(e,name,i,arrayname) {
        
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        objectss[name] = e;
     
        this.setState(arr,()=>this.calculateRow(i,arrayname))
    }
    handlerInputArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        var value=e.target.value
        objectss[name] = value;
        this.setState(arr)
    }
    renderError(name){
        const {isError,errorMessage}=this.props
        var value=''
        if(isError){
            if(errorMessage[name] !=undefined){
                value=errorMessage[name][0]
            }
        }
        return i18n.t(value);
    }
    setConvertion(detail,index,array,key){
        let choose={
            unitList:detail.unit,
            qty:detail.qtyAvailable,
            convertionDetail:detail.convertionDetail,
            index:index,
            key:key,
            array:array
        }
        this.setState({itemChoose:choose,modalDetail:true})
    }
    saveConvertion(status){
        if(status){
            let result=this.formConvertion.current.runSubmit()
            if(result!=null){
                this.state.modalDetail=false
                const arr = [...this.state[this.state.itemChoose.array]];
                const objectss = arr[this.state.itemChoose.index]
                objectss[this.state.itemChoose.key] = result.qtyUse;
                objectss['convertionDetail'] = result.detail;
                this.setState(arr,()=>this.calculateRow(this.state.itemChoose.index,this.state.itemChoose.array))
            }
        }else{
            this.setState({modalDetail:false})
        }
       
    }

    setConvertionLive(detail,index,array,key){
        let choose={
            unitList:detail.unit,
            bandNo:detail.bandNo,
            batchNo:detail.batchNo,
            expDate:detail.expiredDate,
            qty:detail.qtyAvailable,
            convertionDetail:detail.convertionDetail,
            index:index,
            key:key,
            array:array
        }
        this.setState({itemChoose:choose,modalDetail:true})
    }
    saveConvertionLive(status){
        if(status){
            let result=this.formConvertion.current.runSubmit()
            if(result!=null){
                this.state.modalDetail=false
                const arr = [...this.state[this.state.itemChoose.array]];
                const objectss = arr[this.state.itemChoose.index]
                objectss[this.state.itemChoose.key] = result.qtyUse;
                objectss['bandNo'] = result.bandNo;
                objectss['batchNo'] = result.batchNo;
                objectss['expiredDate'] = result.expDate;
                objectss['convertionDetail'] = result.detail;
                this.setState(arr,()=>this.calculateRow(this.state.itemChoose.index,this.state.itemChoose.array))
            }
        }else{
            this.setState({modalDetail:false})
        }
       
    }
    setUnitDefault(i,array){
        let unitList=this.state[array][i].unit
        if(unitList.length >0){
            let indexUnit=unitList.findIndex((e)=> e.current==true);
            const arr = [...this.state[array]];
            const objectss = arr[i]
            objectss['unitValue']=unitList[indexUnit]
            this.setState(arr);
        }
    }
    renderDetail(detail,i){
        const method=parseInt(this.state.transactionMethod);
        switch(method){
            case 1:
                return <React.Fragment key={"detail"+i}>
                            <tr>
                                <td>
                                    <Select
                                        isClearable={false}
                                        isDisabled
                                        name={'itemList'}
                                        onChange={(e)=>{}}
                                        options={this.state.receivingItem}
                                        value={detail.itemValue}
                                    />
                                    <div className="fv-plugins-message-container invalid-feedback">{this.renderError('ReceivingDetail.idPO.'+i)}</div>
                                </td>
                                <td>
                                    <CurrencyInput
                                        id={"qty"+i}
                                        name="qty"
                                        placeholder={i18n.t("Qty Receive")}
                                        defaultValue={0}
                                        value={detail.qty}
                                        decimalsLimit={2}
                                        disabled
                                        onValueChange={(value,name)=>{}}
                                        className='form-control  form-control-sm text-end'
                                        suffix={detail.suffixqty}
                                    />
                                </td>
                                <td>
                                    <InputNumber
                                        id={"qty"+i}
                                        name="price"
                                        placeholder={i18n.t("Price")}
                                        defaultValue={0}
                                        value={detail.unitPrice}
                                        decimalsLimit={2}
                                        onValueChange={async (value,name)=>{
                                            await this.handlerCurrencyArray(value,"unitPrice",i,"arrayDetail");
                                            await this.calculateRow(i,"arrayDetail")
                                        }}
                                        className='form-control  form-control-sm text-end'
                                        prefix='Rp '
                                        labelfield={"false"}
                                        error={this.renderError('InvoiceDetail.unitPrice.'+i)}
                                    />
                                </td>
                                <td>
                                    <InputNumber
                                        id={"discount"+i}
                                        name="discount"
                                        placeholder={i18n.t("Discount Percent")}
                                        defaultValue={0}
                                        value={detail.discount}
                                        decimalsLimit={2}
                                        onValueChange={(value,name)=>this.discountChange(value,i,"arrayDetail",'Percent')}
                                        className='form-control  form-control-sm text-end'
                                        suffix=' %'
                                        labelfield={"false"}
                                    />
                                </td>
                                <td>
                                    <InputNumber
                                        id={"discountPrice"+i}
                                        name="discountPrice"
                                        placeholder={i18n.t("Discount Price")}
                                        defaultValue={0}
                                        value={detail.discountPrice}
                                        decimalsLimit={2}
                                        onValueChange={(value,name)=>this.discountChange(value,i,"arrayDetail",'Price')}
                                        className='form-control  form-control-sm text-end'
                                        prefix='Rp '
                                        labelfield={"false"}
                                    /></td>
                                <td>
                                    <InputNumber
                                        id={"taxPrice"+i}
                                        name="taxPrice"
                                        placeholder={i18n.t("Tax")}
                                        defaultValue={0}
                                        value={detail.taxPrice}
                                        decimalsLimit={2}
                                        onValueChange={(value,name)=>this.handlerCurrencyArray(value,"taxPrice",i,"arrayDetail")}
                                        className='form-control  form-control-sm text-end'
                                        prefix='Rp '
                                        disabled
                                        labelfield={"false"}
                                    />
                                </td>
                                <td>
                                    <InputNumber
                                        id={"nettPrice"+i}
                                        name="nettPrice"
                                        placeholder={i18n.t("Total Nett")}
                                        defaultValue={0}
                                        value={detail.nettPrice}
                                        decimalsLimit={2}
                                        onValueChange={(value,name)=>this.handlerCurrencyArray(value,"nettPrice",i,"arrayDetail")}
                                        className='form-control  form-control-sm text-end'
                                        prefix='Rp '
                                        disabled
                                        labelfield={"false"}
                                    />
                                </td>
                                <td>
                                    <InputNumber
                                        id={"total"+i}
                                        name="total"
                                        placeholder={i18n.t("Total Price")}
                                        defaultValue={0}
                                        value={detail.total}
                                        decimalsLimit={2}
                                        onValueChange={(value,name)=>this.handlerCurrencyArray(value,"total",i,"arrayDetail")}
                                        className='form-control  form-control-sm text-end'
                                        prefix='Rp '
                                        disabled
                                        labelfield={"false"}
                                    />
                                </td>
                            </tr>
            </React.Fragment>
            break;
            case 2:
                return <React.Fragment key={"detail"+i}>
                    <tr>
                        <td>
                            <Select
                                isClearable={true}
                                name={'idPO'}
                                required={true}
                                className={""}
                                size={4}
                                onChange={
                                async (e)=>{
                                    if(e==null){
                                        await this.handlerCurrencyArray(0,"qtyAvailable",i,"arrayDetail")
                                        await this.handlerCurrencyArray([],"convertionDetail",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"qty",i,"arrayDetail")
                                        await this.handlerCurrencyArray('',"suffixqty",i,"arrayDetail")
                                    }
                                    await this.handlerSelectArray(e,"poValue",i,"arrayDetail");
                                    await this.handlerSelectArray(null,"itemValue",i,"arrayDetail");
                                    await this.changeData('itemList','getItem','','arrayDetail',i);
                                }}
                                options={this.state.poList}
                                value={detail.poValue}
                                placeholder={this.state.poText}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">{this.renderError('ReceivingDetail.idPO.'+i)}</div>
                        </td>
                        <td>
                            <Select
                                isClearable={true}
                                name={'idWarehouse'}
                                className={""}
                                size={4}
                                onChange={async (e)=>{
                                    await this.handlerSelectArray(e,"warehouseValue",i,"arrayDetail");
                                    }
                                }
                                options={this.state.warehouseList}
                                value={detail.warehouseValue}
                                placeholder={this.state.warehouseText}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">{this.renderError('ReceivingDetail.idWarehouse.'+i)}</div>
                        </td>
                        <td>
                            <Select
                                isClearable={true}
                                name={'idPODetail'}
                                required={true}
                                className={""}
                                size={4}
                                onChange={async (e)=>{
                                    await this.handlerSelectArray(e,"itemValue",i,"arrayDetail")
                                    if(e !=null){
                                        await this.changeData('unit','getUnitReceiving','','arrayDetail',i);
                                        await this.changeData('poDetail','getItemDetail','','arrayDetail',i,()=>this.fetchPoDetail(i,'arrayDetail'));
                                        let remaining=(e!=null)?e.helper:0
                                        let array=remaining.toString().split(" ");
                                        await this.handlerCurrencyArray(parseFloat(array[0]),"qtyAvailable",i,"arrayDetail")
                                        if(array.length >1){
                                            await this.handlerCurrencyArray(" "+array[1],"suffixqty",i,"arrayDetail")
                                        }
                                    }else{
                                        await this.handlerCurrencyArray(0,"qtyAvailable",i,"arrayDetail")
                                        await this.handlerCurrencyArray([],"convertionDetail",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"unitPrice",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"discount",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"discountPrice",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"taxPrice",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"nettPrice",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"total",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"qty",i,"arrayDetail")
                                        await this.handlerCurrencyArray('',"suffixqty",i,"arrayDetail")
                                        await this.calculateRow(i,'arrayDetail')
                                    }
                                    
                                }}
                                options={detail.itemList}
                                value={detail.itemValue}
                                placeholder={this.state.itemText}
                                
                            />
                            <div className="fv-plugins-message-container invalid-feedback">{this.renderError('ReceivingDetail.idItem.'+i)}</div>
                        </td>
                        <td>
                            <div className='row'>
                            <div className='col-9 p-0'>
                            <CurrencyInput
                                id={"qty"+i}
                                name="qty"
                                placeholder={i18n.t("Qty Receive")}
                                defaultValue={0}
                                value={detail.qty}
                                decimalsLimit={2}
                                disabled
                                onValueChange={(value,name)=>{}}
                                className='form-control  form-control-sm text-end'
                                suffix={detail.suffixqty}
                            />
                            </div>
                            {(detail.itemValue !=null && detail.unit.length >0)?
                                <div className='col p-0'>
                                <Button variant={((detail.convertionDetail.length>0)?"warning":"info")} className='btn p-2' onClick={(e)=>{
                                    this.setConvertion(detail,i,'arrayDetail','qty');
                                }}><FontAwesomeIcon icon={(detail.convertionDetail.length>0)?faBoxOpen:faBoxArchive}/></Button>
                                </div>:''
                            }
                            
                            </div>
                        </td>
                        <td>
                            <InputNumber
                                id={"unitPrice"+i}
                                name="unitPrice"
                                placeholder={i18n.t("Price")}
                                defaultValue={0}
                                value={detail.unitPrice}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.handlerCurrencyArray(value,"unitPrice",i,"arrayDetail")}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                labelfield={"false"}
                                error={this.renderError('PurchaseOrderDetail.unitPrice.'+i)}
                            />
                        </td>
                        <td>
                            <InputNumber
                                id={"discount"+i}
                                name="discount"
                                placeholder={i18n.t("Discount Percent")}
                                defaultValue={0}
                                value={detail.discount}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.discountChange(value,i,"arrayDetail",'Percent')}
                                className='form-control  form-control-sm text-end'
                                suffix=' %'
                                prefix=''
                                labelfield={"false"}
                            />
                        </td>
                        <td>
                            <InputNumber
                                id={"discountPrice"+i}
                                name="discountPrice"
                                placeholder={i18n.t("Discount Price")}
                                defaultValue={0}
                                value={detail.discountPrice}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.discountChange(value,i,"arrayDetail",'Price')}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                labelfield={"false"}
                            /></td>
                        <td>
                            <InputNumber
                                id={"taxPrice"+i}
                                name="taxPrice"
                                placeholder={i18n.t("Tax")}
                                defaultValue={0}
                                value={detail.taxPrice}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.handlerCurrencyArray(value,"taxPrice",i,"arrayDetail")}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                disabled
                                labelfield={"false"}
                            />
                        </td>
                        <td>
                            <InputNumber
                                id={"nettPrice"+i}
                                name="nettPrice"
                                placeholder={i18n.t("Total Nett")}
                                defaultValue={0}
                                value={detail.nettPrice}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.handlerCurrencyArray(value,"nettPrice",i,"arrayDetail")}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                disabled
                                labelfield={"false"}
                            />
                        </td>
                        <td>
                            <InputNumber
                                id={"total"+i}
                                name="total"
                                placeholder={i18n.t("Total Price")}
                                defaultValue={0}
                                value={detail.total}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.handlerCurrencyArray(value,"total",i,"arrayDetail")}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                disabled
                                labelfield={"false"}
                            />
                        </td>
                        <td><Button variant='danger' className='btn btn-sm' onClick={(e)=>this.removeArray(i,'arrayDetail')}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button></td>
                    </tr>
                </React.Fragment>
            break;
            case 3:
                return <React.Fragment key={"detail"+i}>
                    <tr>
                        <td>
                            <Select
                                isClearable={true}
                                name={'itemValue'}
                                required={true}
                                className={""}
                                size={4}
                                onChange={async (e)=>{
                                    await this.handlerSelectArray(e,"itemValue",i,"arrayDetail")
                                    if(e !=null){
                                        await this.changeData('unit','getUnitItem','','arrayDetail',i,()=>this.setUnitDefault(i,"arrayDetail"));
                                    }else{
                                        await this.handlerCurrencyArray(0,"qtyAvailable",i,"arrayDetail")
                                        await this.handlerCurrencyArray([],"convertionDetail",i,"arrayDetail")
                                        await this.handlerCurrencyArray(0,"qty",i,"arrayDetail")
                                    }
                                }}
                                options={this.state.itemList}
                                value={detail.itemValue}
                                placeholder={this.state.itemText}
                                
                            />
                            <div className="fv-plugins-message-container invalid-feedback">{this.renderError('ReceivingDetail.idItem.'+i)}</div>
                        </td>
                        <td>
                            <Select
                                isClearable={true}
                                name={'idWarehouse'}
                                className={""}
                                size={4}
                                onChange={async (e)=>{
                                    await this.handlerSelectArray(e,"warehouseValue",i,"arrayDetail");
                                    }
                                }
                                options={this.state.warehouseList}
                                value={detail.warehouseValue}
                                placeholder={this.state.warehouseText}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">{this.renderError('ReceivingDetail.idWarehouse.'+i)}</div>
                        </td>
                        <td>
                            <div className='row'>
                            <div className='col-9 p-0'>
                            <CurrencyInput
                                id={"qty"+i}
                                name="qty"
                                placeholder={i18n.t("Qty Receive")}
                                defaultValue={0}
                                value={detail.qty}
                                decimalsLimit={2}
                                disabled
                                onValueChange={(value,name)=>{}}
                                className='form-control  form-control-sm text-end'
                                suffix={detail.suffixqty}
                            />
                            </div>
                            {(detail.itemValue !=null && detail.unit.length >0)?
                                <div className='col p-0'>
                                <Button variant={((detail.convertionDetail.length>0)?"warning":"info")} className='btn p-2' onClick={(e)=>{
                                    this.setConvertionLive(detail,i,'arrayDetail','qty');
                                }}><FontAwesomeIcon icon={(detail.convertionDetail.length>0)?faBoxOpen:faBoxArchive}/></Button>
                                </div>:''
                            }
                            
                            </div>
                        </td>
                        <td>
                            <InputNumber
                                id={"unitPrice"+i}
                                name="unitPrice"
                                placeholder={i18n.t("Price")}
                                defaultValue={0}
                                value={detail.unitPrice}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>
                                    this.handlerCurrencyArray(value,"unitPrice",i,"arrayDetail")
                                }
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                labelfield={"false"}
                                error={this.renderError('PurchaseOrderDetail.unitPrice.'+i)}
                            />
                        </td>
                        <td>
                            <InputNumber
                                id={"discount"+i}
                                name="discount"
                                placeholder={i18n.t("Discount Percent")}
                                defaultValue={0}
                                value={detail.discount}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.discountChange(value,i,"arrayDetail",'Percent')}
                                className='form-control  form-control-sm text-end'
                                suffix=' %'
                                prefix=''
                                labelfield={"false"}
                            />
                        </td>
                        <td>
                            <InputNumber
                                id={"discountPrice"+i}
                                name="discountPrice"
                                placeholder={i18n.t("Discount Price")}
                                defaultValue={0}
                                value={detail.discountPrice}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.discountChange(value,i,"arrayDetail",'Price')}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                labelfield={"false"}
                            /></td>
                        <td>
                            <InputNumber
                                id={"taxPrice"+i}
                                name="taxPrice"
                                placeholder={i18n.t("Tax")}
                                defaultValue={0}
                                value={detail.taxPrice}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.handlerCurrencyArray(value,"taxPrice",i,"arrayDetail")}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                disabled
                                labelfield={"false"}
                            />
                        </td>
                        <td>
                            <InputNumber
                                id={"nettPrice"+i}
                                name="nettPrice"
                                placeholder={i18n.t("Total Nett")}
                                defaultValue={0}
                                value={detail.nettPrice}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.handlerCurrencyArray(value,"nettPrice",i,"arrayDetail")}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                disabled
                                labelfield={"false"}
                            />
                        </td>
                        <td>
                            <InputNumber
                                id={"total"+i}
                                name="total"
                                placeholder={i18n.t("Total Price")}
                                defaultValue={0}
                                value={detail.total}
                                decimalsLimit={2}
                                onValueChange={(value,name)=>this.handlerCurrencyArray(value,"total",i,"arrayDetail")}
                                className='form-control  form-control-sm text-end'
                                prefix='Rp '
                                disabled
                                labelfield={"false"}
                            />
                        </td>
                        <td><Button variant='danger' className='btn btn-sm' onClick={(e)=>this.removeArray(i,'arrayDetail')}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button></td>
                    </tr>
                </React.Fragment>
            break;
        }

        
    }
    handlerDateTime(e,name) {
        var key=name;
        var value=e
        this.setState({
            [key]:value
        })
    }
    handlerDateTimeArray(e,name,i,arrayname) {
        const arr = [...this.state[arrayname]];
        const objectss = arr[i]
        if(e==null){
            objectss[name] = null;
        }else{
            objectss[name] = e;
        }
        this.setState(arr)
    }

    addArray(value,key){
        const newRow=value
        const arr=[...this.state[key],newRow]
        this.setState({
            ...this.state, 
            [key]: arr
        })
    }
    removeArray(i,key){
        const arr = this.state[key].filter( (obj,index) => {
            return index !== i;
          })
        this.setState({
            ...this.state, 
            [key]: arr
        })
    }
    
    fetchReceiving(){
        let item=this.state.receivingItem
        if(item !=null){
            var array=[];
            for(var o=0;o<item.length;o++){
                
                var nettPrice=(parseFloat(item[o].unitPrice)||0) - (parseFloat(item[o].discountPrice)||0) +(parseFloat(item[o].taxPrice)||0)
                array.push(
                    {
                        idPo:item[o].idPO,
                        idReceiving:item[o].idReceiving,
                        idReceivingDetail:item[o].id,
                        idPODetail:item[o].idPODetail,
                        itemValue:{value:item[o].idItem,label:item[o].nameItem},
                        description:'',
                        qty:parseFloat(item[o].qty)||0,
                        expiredDate:new Date(item[o].expDate),
                        unit:[],
                        poValue:null,
                        warehouseValue:item[o].warehouseValue,
                        qtyAvailable:0,
                        itemList:[],
                        unitValue:{value:item[o].idUnit,label:item[o].nameUnit},
                        bandNo:item[o].bandNo,
                        batchNo:item[o].batchNo,
                        suffixqty:item[o].unitName,
                        convertionDetail:JSON.parse(item[o].convertionDetail),
                        unitPrice:parseFloat(item[o].unitPrice)||0,
                        discountPrice:parseFloat(item[o].discountPrice)||0,
                        discount:parseFloat(item[o].discount)||0,
                        tax:parseFloat(item[o].tax)||0,
                        taxPrice:parseFloat(item[o].taxPrice)||0,
                        nettPrice:nettPrice,
                        total:nettPrice *(parseFloat(item[o].qty)||0),
                        downPayment:item[o].downPayment,
                        idDP:item[o].idDP,
                    }
                )
            }
            this.setState({arrayDetail:array})
        }
    }
    fetchPoDetail(index,array){
        var arr = [...this.state[array]];
        const objectss = arr[index]
        objectss['unitPrice'] = parseFloat(objectss.poDetail.unitPrice)||0;
        objectss['discount'] = parseFloat(objectss.poDetail.discount)||0;
        objectss['discountPrice'] = parseFloat(objectss.poDetail.discountRate)||0;
        objectss['tax'] = parseFloat(objectss.poDetail.tax)||0;
        objectss['taxPrice'] = parseFloat(objectss.poDetail.taxPrice)||0;
        objectss['nettPrice'] = parseFloat(objectss.poDetail.nettPrice)||0;
        objectss['total'] = parseFloat(objectss.poDetail.total)||0;
        objectss['unitValue'] = {value:objectss.poDetail.idUnit,label:''};
        this.setState(arr, ()=>this.calculateAll())
    }
    
    changeData(keyFill,type=null,key='',array=null,arraykey=null,extraFunction=null){
        const token=cookieManager.getUserToken()
        let params=null
        switch(type){
            case 'getReceivingItem':
                var url=process.env.REST_URL +'helper/get-receiving-item'
                var id=[];
                for(var i=0;i < this.state.receivingCurrent.length; i++){
                    id.push(this.state.receivingCurrent[i].value);
                }
                if(id.length >0 ){
                    params={
                        id:id,
                    }
                }else{
                    this.resetDetail()
                    type=null
                }
                
            break;
            case 'getTop':
                var url=process.env.REST_URL +'helper/get-top-supplier'
                var supplier=((this.state.supplierCurrent !=null)?this.state.supplierCurrent.value:null)
                params={
                    id:supplier,
                }
                break;
            case 'getBank':
                var url=process.env.REST_URL +'helper/get-bank'
                var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                var store=((this.state.storeCurrent !=null)?this.state.storeCurrent.value:null)
                params={
                    branch:branch,
                    store:store
                }
            break;
            
            case 'getCode':
                var url=process.env.REST_URL +'helper/get-invoice-code'
                var date=((this.state.date !=undefined)?moment(this.state.date).format('YYYY-MM-DD') :null)
                var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                var store=((this.state.storeCurrent !=null)?this.state.storeCurrent.value:null)
                params={
                    date:date,
                    branch:branch,
                    store:store
                }
            break;
            case 'getDP':
                var url = process.env.REST_URL + 'helper/get-down-payment'
                var date = ((this.state.date != undefined) ? moment(this.state.date).format('YYYY-MM-DD') : null)
                var supplier = this.state.supplierCurrent==null?null:this.state.supplierCurrent.value
                params = {
                  date: date,
                  supplier: supplier,
                }
                if (supplier == null || date == null) {
                  type = null
                }
                break;
            // case 'getDP':
            //     var url=process.env.REST_URL +'purchasing/payment/dp'
            //     var date=((this.state.date !=undefined)?moment(this.state.date).format('YYYY-MM-DD') :null)
            //     var supplier=this.state.idSupplier
            //     params={
            //         date:date,
            //         supplier:supplier,
            //     }
            //     if(supplier ==null  ||date ==null){
            //         type=null
            //     }
            break;
            case 'getReceiving':
                var url=process.env.REST_URL +'helper/get-receiving'
                var supplier=((this.state.supplierCurrent !=null)?this.state.supplierCurrent.value:null)
                var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                var store=((this.state.storeCurrent !=null)?this.state.storeCurrent.value:null)
                var date=((this.state.date !=undefined)?moment(this.state.date).format('YYYY-MM-DD') :null)
                
                params={
                    supplier:supplier,
                    branch:branch,
                    store:store,
                    date:date,
                    status:1
                }
                if(supplier ==null || branch ==null ||date ==null){
                    type=null
                    this.setState({receivingList:[]});
                }
                break;
            case 'getStore':
                var url=process.env.REST_URL +'helper/get-store'
                var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                params={
                    id:branch,
                }
            break;
            case 'getPo':
                var url=process.env.REST_URL +'helper/get-po-list'
                var idSupplier=((this.state.supplierCurrent !=null)?this.state.supplierCurrent.value:null)
                var idBranch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                var idStore=((this.state.storeCurrent !=null)?this.state.storeCurrent.value:null)
                var date=((this.state.date !=undefined)?moment(this.state.date).format('YYYY-MM-DD') :null)
                params={
                    supplier:idSupplier,
                    date:date,
                    branch:idBranch,
                    store:idStore,
                }
            break;
            case 'getItem':
                var url=process.env.REST_URL +'helper/get-po-items'
                var idPO=((this.state[array][arraykey].poValue !=undefined)?this.state[array][arraykey].poValue.value:null)
                params={id:idPO}
            break;
            case 'getItemList':
                var url=process.env.REST_URL +'helper/get-item'
            break;
            case 'getItemDetail':
                var url=process.env.REST_URL +'helper/get-po-item-detail'
                var itemID=((this.state[array][arraykey].itemValue !=null)?this.state[array][arraykey].itemValue.value:null)
                params={
                   id:itemID
                }
            break;
            case 'getWarehouse':
                var url=process.env.REST_URL +'helper/get-setup-warehouse'
                var idBranch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
                var idStore=((this.state.storeCurrent !=null)?this.state.storeCurrent.value:null)
                params={
                    branch:idBranch,
                    store:idStore
                }
            break;
            case 'getUnitReceiving':
                var url=process.env.REST_URL +'helper/get-unit-receiving'
                var item=((this.state[array][arraykey].itemValue !=null)?this.state[array][arraykey].itemValue.value:null)
                params={
                    id:item,
                }
            break;
            case 'getUnitItem':
                var url=process.env.REST_URL +'helper/get-unit-item'
                var item=((this.state[array][arraykey].itemValue !=null)?this.state[array][arraykey].itemValue.value:null)
                params={
                    id:item,
                }
            break;
        }

        if(type !=null){
            axios({
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`
                },
                url:url,
                responseType: 'json',
                params:params
              })
              .then((response) => {
                if (response && response.status === 200 && response.data) {
                    if(array!=null){
                        var arr = [...this.state[array]];
                        const objectss = arr[arraykey]
                        if(key==''){
                            objectss[keyFill] = response.data.result;
                        }else{
                            objectss[keyFill] = response.data.result[key];
                        }
                    }else{
                        if(key==''){
                           var arr= {[keyFill]:response.data.result}
                        }else{
                            var arr= {[keyFill]:response.data.result[key]}
                        }
                    }
                    if(extraFunction!=null){
                        this.setState(arr,extraFunction)
                    }else{
                        this.setState(arr)
                    }
                   
                }
              })
              .catch((error) => {
                console.log(error)
              });
        }

    }
    resetDetail(){
        this.setState({arrayDetail:[
            {
                idPo:'',
                idReceiving:'',
                idReceivingDetail:'',
                idPODetail:'',
                itemValue:null,
                description:'',
                qty:0,
                unit:[],
                poValue:null,
                warehouseValue:null,
                qtyAvailable:0,
                itemList:[],
                bandNo:'',
                batchNo:'',
                expDate:new Date(),
                suffixqty:'',
                convertionDetail:[],
                unitPrice:0,
                discountPrice:0,
                discount:0,
                tax:0,
                unitValue:null,
                taxPrice:0,
                nettPrice:0,
                total:0,
                poDetail:null
            }
        ]},async()=>{this.calculateAll()})
    }

    async setItem(){
        let p=parseInt(this.state.transactionMethod)
        this.resetDetail();
        switch(p){
            case 1:
                await this.changeData('receivingList','getReceiving','');
                break;
            case 2:
                await this.changeData('poList','getPo','');
                break;
            case 3:
                await this.changeData('itemList','getItemList','');
                break;
        }
    }

    useDP(){
        let subTotal=this.state.subTotal
        let refund=this.state.refund
        let totalRemaining=this.state.totalRemaining
        let payment=this.state.totalPayment

        let dp = parseFloat(this.state.downPayment)||0 
        if(this.state.useDP == true){
            payment=(parseFloat(payment)||0) + (parseFloat(dp)||0);
        }else{
            payment=(parseFloat(payment)||0) - (parseFloat(dp)||0);
        }
        totalRemaining=(parseFloat(subTotal)||0) - (parseFloat(payment)||0) ;
        this.setState({
            payment:payment,
            totalPayment:payment,
            totalRemaining:totalRemaining,
            refund:refund
        })
    }

    // setDp(){
    //     this.setState({totalDP:this.state.downpayment});
    // }
    setDP(){
        var DP = !this.state.useDP
     
        this.setState({
            useDP:DP
        },()=>this.useDP())
        
    }

    // useDP(e){
    //     this.setState({checkDownpayment:!this.state.checkDownpayment},()=>this.calculateAll())
    // }

    updateTop(){
        const d = new Date(this.state.dateSupplier);

        d.setDate(d.getDate() + (parseInt(this.state.top)||0));
        this.setState({dueDate:d})
    }
    render(){
        const {data,isNewRecord,helperInvoice}=this.props
        this.state.branchList=((helperInvoice !=null)?helperInvoice.branch:[])
        this.state.supplierList=((helperInvoice !=null)?helperInvoice.supplier:[])
        // this.changeData('downpayment','getDP','totalDP',null,null,()=>this.setDp())
        if(data !=null && !isNewRecord){
            if(this.state.isUpdate==0){
                this.state.poNo=data.poNo,
                this.state.description=data.description,
                this.state.datePo=new Date(data.date)
                this.state.dateDelivery=new Date(data.deliveryDate)
                this.state.idSupplier=data.id_supplier
                this.state.supplierCurrent={value:data.id_supplier, label:data.name_supplier}
                this.state.arrayDetail=[]
                for(var po=0;po<data.po_detail.length;po++){
                    this.state.arrayDetail.push(
                        {
                            itemValue:{value:data.po_detail[po].id_item, label:data.po_detail[po].item_name},
                            description:data.po_detail[po].description,
                            qty:data.po_detail[po].qty,
                            unitValue:{value:data.po_detail[po].id_unit, label:data.po_detail[po].unit_name},
                            price:parseFloat(data.po_detail[po].unitPrice)||0,
                            discount:parseFloat(data.po_detail[po].discount)||0,
                            discountPrice:parseFloat(data.po_detail[po].discount_rate)||0,
                            priceTax:parseFloat(data.po_detail[po].tax_price)||0,
                            priceNett:parseFloat(data.po_detail[po].nett_price)||0,
                            priceTotal:parseFloat(data.po_detail[po].total)||0,
                            isPr:false,
                            idPr:'',
                        }
                    )
                }
                this.state.isUpdate=1
            }
        }
        return (
            <React.Fragment>
            <form>
                <div className='row'>
                    <div className="col-3">
                        <Input
                            type={"input"}
                            name={"invoiceNo"}
                            title={i18n.t("Invoice No")}
                            required
                            disabled
                            value={this.state.invoiceNo}
                            handler={this.handler}
                            error={this.renderError('Invoice.invoiceNo')}
                        />
                    </div>
                    <div className="col-3">
                        <Select2 
                            isClearable={true}
                            name={'idBranch'}
                            required={true}
                            size={4}
                            onChange={async (e)=>{
                                if(e!=this.state.branchCurrent){
                                    await this.setState({storeCurrent:null,storeList:[],poList:[]});
                                }
                                await this.setState({BGAccountCurrent:null});
                                await this.handlerSelect(e,"idBranch","branchCurrent");
                                await this.changeData('invoiceNo','getCode','code');
                                await this.changeData('storeList','getStore','');
                                await this.changeData('BGAccountList','getBank','');
                                // await this.changeData('tempDP','getDP','totalDP',null,null,()=>this.setDp())
                                if(this.state.transactionMethod!=3){
                                    await this.setItem();
                                }
                               
                                await this.changeData('warehouseList','getWarehouse');
                                
                            }}
                            options={this.state.branchList}
                            title={i18n.t("Branch")}
                            value={this.state.branchCurrent}
                            placeholder={this.state.branchText}
                            error={this.renderError('Invoice.idBranch')}
                        />
                    </div>
                    <div className="col-3">
                        <Select2 
                            isClearable={true}
                            name={'idStore'}
                            size={4}
                            onChange={async (e)=>{
                                    await this.setState({BGAccountCurrent:null});
                                    await this.handlerSelect(e,"idStore","storeCurrent")
                                    if(this.state.transactionMethod!=3){
                                        await this.setItem();
                                    }
                                    await this.changeData('warehouseList','getWarehouse');
                                    await this.changeData('BGAccountList','getBank','');
                                    // await this.changeData('tempDP','getDP','totalDP',null,null,()=>this.setDp())
                                    if(e ==null){
                                        await this.setState({poList:[]})
                                    }
                            }}
                            options={this.state.storeList}
                            title={i18n.t("Store")}
                            value={this.state.storeCurrent}
                            placeholder={this.state.storeText}
                            error={this.renderError('Invoice.idStore')}
                        />
                    </div>
                    <div className="col-3">
                        <DatePickerCustom
                            name={"date"}
                            title={i18n.t("Invoice Date")}
                            required={true}
                            selected={this.state.date}
                            onSelect={async (e)=>{
                                    await this.handlerDateTime(e,'date');
                                    await this.changeData('invoiceNo','getCode','code');
                                    // await this.changeData('tempDP','getDP','totalDP',null,null,()=>this.setDp())
                                    if(this.state.transactionMethod!=3){
                                        await this.setItem();
                                    }
                                }
                            }
                            onChange={(e)=>{}}
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMM yyyy"
                            maxDate={new Date()}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="col-3">
                        <Select2 
                            isClearable={true}
                            name={'idSupplier'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async (e)=>{
                                    await this.handlerSelect(e,"idSupplier","supplierCurrent");
                                    // await this.changeData('tempDP','getDP','totalDP',null,null,()=>this.setDp())
                                    if(this.state.transactionMethod!=3){
                                        await this.setItem();
                                    }
                                    await this.changeData('top','getTop','top',null,null,()=>this.updateTop())
                                }
                            }
                            options={this.state.supplierList}
                            title={i18n.t("Supplier")}
                            value={this.state.supplierCurrent}
                            placeholder={this.state.supplierText}
                            error={this.renderError('PurchaseOrder.idSupplier')}
                        />
                    </div>
                    <div className="col-3">
                        <Radio
                            list={this.state.transactionMethodList}
                            name={"transactionMethod"}
                            title={i18n.t("Transaction Method")}
                            inline={true}
                            value={this.state.transactionMethod}
                            handler={async (e)=>{
                                    var valueBefore=this.state.transactionMethod
                                    if(valueBefore !=e.target.value){
                                        await this.resetDetail();
                                    }
                                    await this.handler(e)
                                    await this.setItem();
                                }
                            }
                            error={this.renderError('Invoice.transactionMethod')}
                        />
                    </div>
                    <div className="col-3">
                        <Radio
                            list={this.state.paymentTypeList}
                            name={"paymentType"}
                            title={i18n.t("Payment Type")}
                            inline={true}
                            value={this.state.paymentType}
                            handler={this.handler}
                            error={this.renderError('Invoice.paymentType')}
                        />
                    </div>
                    {/* {(this.state.paymentType =='BG')?
                    <>
                         <div className="col-3">
                           <DatePickerCustom
                                name={"BGDate"}
                                title={i18n.t("BG Date")}
                                required={true}
                                selected={this.state.BGdate}
                                onSelect={async (e)=>{
                                        await this.handlerDateTime(e,'BGdate');
                                    }
                                }
                                startDay={0}
                                autoComplete={"off"}
                                dateFormat="dd MMM yyyy"
                                minDate={this.state.date}
                                className="form-control form-control-sm"
                            />
                        </div>
                        <div className="col-3">
                            <Input
                                // disabled={true}
                                type={"input"}
                                name={"BGNo"}
                                title={i18n.t("No. BG")}
                                value={this.state.BGNo}
                                handler={this.handler}
                                error={this.renderError('Invoice.BGNo')}
                            />
                        </div>
                    </>
                    :''} */}
                    {(this.state.paymentType =='Cash')?
                    <div className="col-3">
                        <Select2 
                            isClearable={true}
                            name={'BGAccount'}
                            required={true}
                            className={""}
                            size={4}
                            onChange={async (e)=>{
                                    await this.handlerSelect(e,"BGAccount","BGAccountCurrent");
                                }
                            }
                            options={this.state.BGAccountList}
                            title={this.state.BGAccountText}
                            value={this.state.BGAccountCurrent}
                            placeholder={this.state.BGAccountText}
                            error={this.renderError('Invoice.BGAccount')}
                        />
                    </div>:''}
                    
                    {(this.state.transactionMethod==1)?
                        <div className="col-3">
                            <Select2 
                                isClearable={true}
                                name={'Receiving'}
                                required={true}
                                className={""}
                                size={4}
                                onChange={async (e)=>{
                                        await this.handlerSelect(e,"Receiving","receivingCurrent");
                                        await this.changeData('receivingItem','getReceivingItem','',null,null,async ()=>{await this.fetchReceiving();await this.calculateAll()})
                                    
                                    }
                                }
                                isMulti
                                options={this.state.receivingList}
                                title={this.state.receivingText}
                                value={this.state.receivingCurrent}
                                placeholder={this.state.receivingText}
                            />
                        </div>
                    :''}
                        
                    <div className="col-3">
                        <Input
                            type={"input"}
                            name={"supplierInvoice"}
                            title={i18n.t("Supplier Invoice No.")}
                            value={this.state.supplierInvoice}
                            handler={this.handler}
                            error={this.renderError('Invoice.supplierInvNo')}
                        />
                    </div>
                    <div className="col-3">
                        <DatePickerCustom
                            name={"date"}
                            title={i18n.t("Invoice Supplier Date")}
                            required={true}
                            selected={this.state.dateSupplier}
                            onSelect={async (e)=>{
                                await this.handlerDateTime(e,'dateSupplier');
                                await this.updateTop();
                                }
                            }
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMM yyyy"
                            maxDate={new Date()}
                            className="form-control form-control-sm"
                        />
                    </div>
                    <div className="col-3">
                        <DatePickerCustom
                            name={"date"}
                            title={i18n.t("Due Date")}
                            required={true}
                            selected={this.state.dueDate}
                            onSelect={async (e)=>{
                                    await this.handlerDateTime(e,'dueDate');
                                }
                            }
                            startDay={0}
                            autoComplete={"off"}
                            dateFormat="dd MMM yyyy"
                            minDate={this.state.dateSupplier}
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <br />
                <hr />
                <div className='row'>
                    <div className="col-12">
                        <Table striped bordered hover>
                            <thead>
                                {(this.state.transactionMethod==1)? 
                                    <tr>
                                        <th width="24%" >{i18n.t("Item")}</th>
                                        <th width="13%" className='text-center'>{i18n.t("Qty")}</th>
                                        <th width="11%" className='text-center'>{i18n.t("Price")}</th>
                                        <th width="8%" className='text-center'>{i18n.t("Discount Percent")}</th>
                                        <th width="11%" className='text-center'>{i18n.t("Discount Price")}</th>
                                        <th width="11%" className='text-center'>{i18n.t("Tax")}</th>
                                        <th width="11%" className='text-center'>{i18n.t("Nett Price")}</th>
                                        <th width="11%" className='text-end'>{i18n.t("Total")}</th>
                                    </tr>
                                :''}
                                {(this.state.transactionMethod==2)? 
                                    <tr>
                                        <th width="13%" >{i18n.t("No PO")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Warehouse")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Item")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Qty")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Price")}</th>
                                        <th width="5%" className='text-center'>{i18n.t("Discount Percent")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Discount Price")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Tax")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Nett Price")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Total")}</th>
                                        <th></th>
                                    </tr>
                                :''}
                                {(this.state.transactionMethod==3)? 
                                    <tr>
                                        <th width="15%" className='text-center'>{i18n.t("Item")}</th>
                                        <th width="15%" className='text-center'>{i18n.t("Warehouse")}</th>
                                        <th width="15%" className='text-center'>{i18n.t("Qty")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Price")}</th>
                                        <th width="5%" className='text-center'>{i18n.t("Discount Percent")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Discount Price")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Tax")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Nett Price")}</th>
                                        <th width="10%" className='text-center'>{i18n.t("Total")}</th>
                                        <th></th>
                                    </tr>
                                :''}
                            </thead>
                            <tbody>
                                {this.state.arrayDetail.map((detail,index)=>{
                                    return  this.renderDetail(detail,index);
                                })}
                            </tbody>
                        </Table>
                        {
                            (this.state.transactionMethod!=1)?<Button variant='success' onClick={ (e)=>
                                this.addArray(
                                    {
                                        idPo:'',
                                        idReceiving:'',
                                        idReceivingDetail:'',
                                        idPODetail:'',
                                        itemValue:null,
                                        description:'',
                                        qty:0,
                                        unit:[],
                                        poValue:null,
                                        warehouseValue:null,
                                        qtyAvailable:0,
                                        itemList:[],
                                        bandNo:'',
                                        batchNo:'',
                                        expDate:new Date(),
                                        suffixqty:'',
                                        convertionDetail:[],
                                        unitPrice:0,
                                        discountPrice:0,
                                        discount:0,
                                        tax:0,
                                        taxPrice:0,
                                        nettPrice:0,
                                        total:0,
                                        unitValue:null,
                                        poDetail:null
                                    },
                                    'arrayDetail'
                                )
                                }
                            >
                                {i18n.t("Add Item")}
                            </Button>:''
                        }
                        
                    </div>
                </div>
                <div className='row'>
                    <div className="d-flex flex-end">
                        <div className='col-4'>
                            <Table borderless responsive="sm">
                                <tbody style={{fontWeight:'bold'}}>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Total Price")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"subTotal"}
                                                name="subTotal"
                                                placeholder={i18n.t("Sub Total")}
                                                defaultValue={0}
                                                value={this.state.subTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <td><label className='label-control' htmlFor='Payment'>{i18n.t("Discount Invoice")}</label></td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"discountHeader"}
                                                name="discountHeader"
                                                placeholder={i18n.t("Discount Invoice")}
                                                defaultValue={0}
                                                value={this.state.discountHeader}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>
                                                    this.setState({discountHeader:value},()=>this.calculateAll())
                                                }
                                                className='p-2 form-control form-control-xs  text-end'
                                                prefix='Rp '
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Discount Total")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"discountTotal"}
                                                name="discountTotal"
                                                placeholder={i18n.t("Discount Total")}
                                                defaultValue={0}
                                                value={this.state.discountTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <td>{i18n.t("Tax Total")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"taxTotal"}
                                                name="taxTotal"
                                                placeholder={i18n.t("Tax Total")}
                                                defaultValue={0}
                                                value={this.state.taxTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="align-middle mt-5" key={"DP"}>
                                        <td>
                                            <span className='get-inline'>
                                                <label className='label-control ' htmlFor='useDP'>{i18n.t("Down Payment")}</label>
                                            </span>  
                                        </td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"downPayment"}
                                                name="downPayment"
                                                placeholder={i18n.t("Down Payment")}
                                                defaultValue={0}
                                                value={this.state.totalDP}
                                                decimalsLimit={2}
                                                onValueChange={(value, name) => { }}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr>
                                    {/* {(this.state.tempDP>0)?
                                    <tr className="align-middle">
                                        <td><label className='label-control required' htmlFor='Payment'>Down Payment</label></td>
                                        <td>:</td>
                                        <td class="row">
                                            <div className='col-10'>
                                                <InputNumber
                                                    id={"totalDP"}
                                                    name="totalDP"
                                                    placeholder="total Down Payment"
                                                    defaultValue={0}
                                                    value={(this.state.checkDownpayment)?this.state.totalDP:this.state.tempDP}
                                                    decimalsLimit={2}
                                                    onValueChange={(value,name)=>
                                                        this.setState({totalDP:value},()=>this.calculateAll())
                                                    }
                                                    className='p-2 form-control form-control-xs  text-end'
                                                    prefix='Rp '
                                                    labelfield={"false"}
                                                    // disabled={(this.state.checkDownpayment)}
                                                    disabled={true}
                                                />
                                            </div>
                                            <div className='col-2'>
                                                <SwitchButton
                                                    labelfield={'false'} 
                                                    onChange={(e)=>this.useDP(e)}
                                                    name={'choosePayment'}
                                                    value={this.state.checkDownpayment}
                                                    checked={this.state.checkDownpayment?true:false}
                                                    className={'h-20px w-30px'}
                                                ></SwitchButton>
                                            </div>
                                        </td>
                                    </tr>:''} */}
                                    {/* <tr className="align-middle">
                                        <td>{i18n.t("Grand Total")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"grandTotal"}
                                                name="grandTotal"
                                                placeholder={i18n.t("Grand Total")}
                                                defaultValue={0}
                                                value={this.state.grandTotal}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                            />
                                        </td>
                                    </tr> */}
                                    <tr className="align-middle" style={{fontSize:'16px'}}>
                                        <td>{i18n.t("Grand Total")}</td>
                                        <td>:</td>
                                        <td>
                                            <InputNumber
                                                id={"totalRemaining"}
                                                name="totalRemaining"
                                                placeholder={i18n.t("Remaining Payment")}
                                                defaultValue={0}
                                                value={this.state.totalRemaining}
                                                decimalsLimit={2}
                                                onValueChange={(value,name)=>{}}
                                                className='p-0 form-control form-control-xs form-control-flush text-end'
                                                prefix='Rp '
                                                readOnly
                                                labelfield={"false"}
                                                style={{fontSize:'16px',fontWeight:'bold'}}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </form>
            {
                (this.state.transactionMethod==3) ?
                <Modal
                show={this.state.modalDetail}
                backdropClassName="next1"
                centered
                size='lg'
           >
            <Modal.Header >
            <Modal.Title>{this.state.titleModal}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormConvertionLive 
                    data={this.state.itemChoose}
                    isNewRecord={false}
                    ref={this.formConvertion}
                />
            </Modal.Body>
            <Modal.Footer className={'flex-center'}>
                <Button variant='secondary' onClick={(e)=>this.saveConvertionLive(false)}>{i18n.t("Close")}</Button>
                <Button variant='primary' onClick={(e)=>this.saveConvertionLive(true)}>{i18n.t("Convertion")}</Button>
            </Modal.Footer>
           </Modal>:<Modal
                show={this.state.modalDetail}
                backdropClassName="next1"
                centered
               >
                <Modal.Header >
                <Modal.Title>{this.state.titleModal}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <FormConvertion 
                        data={this.state.itemChoose}
                        isNewRecord={false}
                        ref={this.formConvertion}
                    />
                </Modal.Body>
                <Modal.Footer className={'flex-center'}>
                    <Button variant='secondary' onClick={(e)=>this.saveConvertion(false)}>{i18n.t("Close")}</Button>
                    <Button variant='primary' onClick={(e)=>this.saveConvertion(true)}>{i18n.t("Convertion")}</Button>
                </Modal.Footer>
               </Modal>   
                }
           
           </React.Fragment>
        );
    }
}

export default Form;