import React, { Component, Fragment } from "react";
import TableStockCard from "./table";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import FormSupplier, { FormStock } from "./stock";
import i18n from "../../../../i18n";

const MySwal = withReactContent(Swal);
export class DataStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: 0,
      search: {
        limit: 25,
      },
      titleModal: "",
      listData: [],
      columns: [
        {
          key: "name_item",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name Item"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
      ],
      columnWH: [
        {
          key: "name_wh",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name Item"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "name_sw",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name Sub Warehouse"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "total_stock",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Total Stock"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },     
      ],
    };
  }

  renderTable() {
    if(this.state.listData != null && this.state.listData.length > 0 && this.state.listData != undefined){
        return (
          <TableStockCard 
            {...this.props} 
            data={this.state.listData}
            columns={this.state.columns} 
            show={true}
            >

           </TableStockCard>
        );
    }else{
      // return (
      //   <table>
      //     <tbody>
      //     <tr key={"notfound"}>
      //       <td
      //         className="text-center"
      //         colSpan={6}
      //       >
      //         {i18n.t("No Data Found")}
      //       </td>
      //     </tr> 
      //     </tbody>
      //   </table>
      // );
    }
  }
  
  render() {
    const { isProcessing, data,branch } = this.props;
    if(data != null && data != undefined && data!=[]){
      this.state.listData = data.data;
    }

    return (
      <div id="invoice-page">
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              {/* <div className="card-body"> */}
                  <FormStock
                    {...this.props}
                    data={data}
                    branch={branch}
                  />
                 {isProcessing ?<div className="text-center">
                  <div className="spinner-border  text-primary text-center  clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                  </div>:null}
              {/* </div> */}
             
            </div>
            { data != null && data != undefined?  
            <Fragment>
            
              <div className="row">
                {  this.renderTable() }
             
              </div>
            </Fragment>
            
            
            
            
            
            
            : ""}
          </div>
        </div>
      </div>
    );
  }
}

export default DataStock;
