import React from "react";
import i18n from "../../../i18n";
import { formatNumber } from "../../utilities/Class/FormatNumber";
import LabelStock from "../../utilities/Status/StatusStock";
import { Link } from "react-router-dom";

export default function SectionStockReport(props) {
    const arr = props.data;
    return (
        <div className="card card-flush h-xl-100">

            <div className="card-header pt-7">

                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-900">{i18n.t("Stock Report")}</span>
                    {/* <span className="text-gray-500 mt-1 fw-semibold fs-6">Total 2,356 Items in the Stock</span> */}
                </h3>


                <div className="card-toolbar">

                    <div className="d-flex flex-stack flex-wrap gap-4">

                        <a href={"/stock-card"} className="btn btn-light btn-sm">{i18n.t("View Stock")}</a>

                    </div>

                </div>

            </div>


            <div className="card-body  table-responsive">

                <table className="table align-middle table-row-dashed fs-6 gy-3" id="kt_table_widget_5_table">

                    <thead>

                        <tr className="text-center text-gray-500 fw-bold fs-7 text-uppercase gs-0" style={{textAlign:'center'}}>
                            <th className="min-w-150px">{i18n.t("Item Code")}</th>
                            <th className="pe-3 min-w-100px">{i18n.t("Item Name")}</th>
                            <th className="pe-3 min-w-100px">{i18n.t("No. Band")}</th>
                            <th className="pe-0 min-w-75px">{i18n.t("Status")}</th>
                            <th className="pe-0 min-w-75px">{i18n.t("Qty")}</th>
                        </tr>

                    </thead>


                    <tbody className="fw-bold text-gray-600">
                        {arr.stockReport != null ? arr.stockReport.map((item, i) => {
                            return (
                                <tr key={"data" + i} style={{textAlign:'center'}}>
                                    <td>
                                        {item.code}
                                    </td>
                                    <td>
                                       <Link
                                        to={"/master/item/view/" + item.id}
                                        onClick={() => changeLocation("/master/item/view/" + item.id)}
                                    >
                                        <span className="text-gray-800 text-hover-primary">{item.name}</span>
                                    </Link>
                                    </td>
                                    <td>
                                        {item.band}
                                    </td>
                                    <td>
                                        <LabelStock value={item.status} />
                                    </td>
                                    <td>
                                        {formatNumber(item.total)}
                                    </td>
                                </tr>
                            );
                        })
                            : null}
                        {/* <tr>

                        <td>
                            <a href="#" className="text-gray-900 text-hover-primary">Macbook Air M1</a>
                        </td>


                        <td className="text-end">#XGY-356</td>


                        <td className="text-end">02 Apr, 2024</td>


                        <td className="text-end">$1,230</td>


                        <td className="text-end">
                            <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                        </td>


                        <td className="text-end" data-order="58">
                            <span className="text-gray-900 fw-bold">58 PCS</span>
                        </td>

                    </tr>
                    <tr>

                        <td>
                            <a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-900 text-hover-primary">Surface Laptop 4</a>
                        </td>


                        <td className="text-end">#YHD-047</td>


                        <td className="text-end">01 Apr, 2024</td>


                        <td className="text-end">$1,060</td>


                        <td className="text-end">
                            <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                        </td>


                        <td className="text-end" data-order="0">
                            <span className="text-gray-900 fw-bold">0 PCS</span>
                        </td>

                    </tr>
                    <tr>

                        <td>
                            <a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-900 text-hover-primary">Logitech MX 250</a>
                        </td>


                        <td className="text-end">#SRR-678</td>


                        <td className="text-end">24 Mar, 2024</td>


                        <td className="text-end">$64</td>


                        <td className="text-end">
                            <span className="badge py-3 px-4 fs-7 badge-light-primary">In Stock</span>
                        </td>


                        <td className="text-end" data-order="290">
                            <span className="text-gray-900 fw-bold">290 PCS</span>
                        </td>

                    </tr>
                    <tr>

                        <td>
                            <a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-900 text-hover-primary">AudioEngine HD3</a>
                        </td>


                        <td className="text-end">#PXF-578</td>


                        <td className="text-end">24 Mar, 2024</td>


                        <td className="text-end">$1,060</td>


                        <td className="text-end">
                            <span className="badge py-3 px-4 fs-7 badge-light-danger">Out of Stock</span>
                        </td>


                        <td className="text-end" data-order="46">
                            <span className="text-gray-900 fw-bold">46 PCS</span>
                        </td>

                    </tr>
                    */}

                    </tbody>

                </table>

            </div>

        </div>
    );

}


