import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import StockCardActions from '../../../../reducers/modules/mutation/stock-card/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Navigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const url=process.env.REST_URL +'stock-card'

export default class StockCardMiddleware {

  static create(params){
    return (dispatch) => {
      dispatch(StockCardActions.create());
    };
  }

  static update(params){
    return (dispatch) => {
      StockCardMiddleware.showOne(dispatch, params);
      dispatch(StockCardActions.update());
    };
  }
  static resetList(){
    return (dispatch) => {
        dispatch(StockCardActions.resetList());
      };
  }
  static getData(params){
      return (dispatch) => {
          dispatch(StockCardActions.process());
          StockCardMiddleware.showList(dispatch, params);
        };
  }

  static searchData(params){
    return (dispatch) => {
        dispatch(StockCardActions.process());
        StockCardMiddleware.runSearch(dispatch, params);
      };
}
  static showExcel(params){
    return (dispatch) => {
        dispatch(StockCardActions.process());
        StockCardMiddleware.beforeShowExcel(dispatch, params);
      };
  }

  static listing(params){
    return (dispatch) => {
        dispatch(StockCardActions.process());
        StockCardMiddleware.showlisting(dispatch, params);
      };
  }
  static reset(){
    return (dispatch) => {
        dispatch(StockCardActions.reset());
      };
}
  static getOne(params){
    return (dispatch) => {
        dispatch(StockCardActions.view());
        StockCardMiddleware.showOne(dispatch, params);
      };
  }

  static getToken(){
      return cookieManager.getUserToken();
  }

  static getHelper(params=null){
    return (dispatch) => {
      StockCardMiddleware.showHelper(dispatch,params);
      };
  }

  static getPrint(params) {
    return (dispatch) => {
      dispatch(StockCardActions.beforePrint());
      StockCardMiddleware.showPrint(dispatch, params);
    };
  }

 static runSearch(dispatch, params) {
    let urlSearch = process.env.REST_URL + "stock-card/search";
    // this.setState({ isProcessing: true });
    const token = cookieManager.getUserToken();
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: urlSearch,
      responseType: "json",
      data: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
              dispatch(StockCardActions.list(response.data.result));
            }
          })
          .catch((error) => {
            StockCardMiddleware.alertHendler(dispatch, error);
          });
          // console.log(response.data.result)
          // var detail = response.data.result;
          
          // const arr = {
          //   link: detail.links,
          //   data: detail.data,
          //   currentPage: detail.current_page,
          //   totalData: detail.total,
          //   page: detail.current_page,
          //   isProcessing: false,
          //   fulldata :detail.data
          // };
          // this.setState(arr);
      //   }
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
  }

  static showPrint(dispatch, params) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${StockCardMiddleware.getToken()}`,
      },
      url: process.env.REST_URL +'report/cash-flow/print',
      responseType: "json",
      data:params
      // method: 'POST', 
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Bearer ${StockCardMiddleware.getToken()}`
      // },
      // url: process.env.REST_URL +'report/cash-flow/print',
      // responseType: 'json',
      // params:params
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(StockCardActions.print(response.data.result));
          console.log('response',response.data.result);
        }
      })
      .catch((error) => {
        StockCardMiddleware.alertHendler(dispatch, error);
      });
  }

 
  static showHelper(dispatch,params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${StockCardMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-helper-work-sheet' ,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(StockCardActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        StockCardMiddleware.alertHendler(dispatch,error)
      });
  }

  static showList(dispatch, params){
    const data = {
      idBranch : params,
      limit : 25,
      page : 1
    }
    
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${StockCardMiddleware.getToken()}`,
      },
      url: url + '/show',
      responseType: "json",
      data: data,
    })
      .then((response) => {
        console.log('response',response)
        if (response && response.status === 200 && response.data) {
          dispatch(StockCardActions.list(response.data.result));
        }
      })
      .catch((error) => {
        StockCardMiddleware.alertHendler(dispatch, error);
      });
  }

  static showlisting(dispatch, params){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${StockCardMiddleware.getToken()}`
        },
        url: url,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(StockCardActions.list(response.data.result));
        }
      })
      .catch((error) => {
        StockCardMiddleware.alertHendler(dispatch,error)
      });
}

  static showOne(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${StockCardMiddleware.getToken()}`
        },
        url: url +'/'+params,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(StockCardActions.view(response.data.result));
        }
      })
      .catch((error) => {
        StockCardMiddleware.alertHendler(dispatch,error)
      });
  }

  static alertHendler(dispatch,result,isSwal=false,extraMessage='',type='error'){
    if(type=='error'){
      if (result.response) {
        const status=result.response.status
        switch(status){
          
          case 500:
            if(isSwal){
              const data=result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title:'Delete Error',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              })
            }else{
              const data=result.response.data
              dispatch(StockCardActions.error(data.error));
            }
          break;  
          case 403:
            console.log('Error 403');
          break;     
          case 404:
            console.log('Error 404');
          break;   
          case 401:
            const data=result.response.data
              MySwal.fire({
                title:'Login Invalid',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              }).then((result)=>{
                  if(result.isConfirmed){
                    <Navigate to="/login"></Navigate>
                  }
              })
          break;   
        }
      } else if (result.request) {
        MySwal.fire({
          title:'Internal Server Error',
          html:'Please info your Developer to fix this',
          icon:'error',
          showConfirmButton:true,
        })
        dispatch(StockCardActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
      }
    }else{
      MySwal.fire({
        title:'Success',
        html:extraMessage,
        icon:'success',
        showConfirmButton:true,
      })
    }
  }
}
