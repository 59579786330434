import React, { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {Navigate, Outlet } from "react-router-dom";
import { bindActionCreators } from "redux";
import Footer from '../../components/layouts/Footer'
import Header from '../../components/layouts/Header'
import useScript from "../../components/utilities/useScript";
import AuthMiddleware from "../../reducers/auth/middleware";
// import AccessControl from "../../components/utilities/Class/AccessControl";

const mapStateToProps = ({ auth}) => ({
  isAuthenticated: auth.isAuthenticated,
  authUser:auth.authUser
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    runlogout:()=>AuthMiddleware.logout(),
  }, dispatch);
};

const Layout=(props)=> {
  const {isAuthenticated,runlogout}=props
   // State to store the window width and height
   const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Function to handle resizing
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect (() => {
    window.addEventListener('resize', handleResize);
    useScript("/assets/js/jquery-3.6.2.min.js");
    useScript("/assets/js/SmoothScroll.min.js");
    useScript("/assets/js/bootstrap.bundle.min.js");
    useScript("https://cdn.datatables.net/1.10.8/js/jquery.dataTables.min.js");
    useScript("https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.30.1/moment.min.js");
    useScript("/assets/plugins/global/plugins.bundle.js");
    useScript("/assets/js/scripts.bundle.js");
    useScript("/assets/js/widgets.bundle.js");
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const logout=()=>{
    runlogout()
  }

  if(!isAuthenticated){
    return <Navigate to="login"></Navigate>
  }
  return (
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header logout={logout} {...props}/>
          
            <Outlet />
          <Footer/>
          </div>
        </div>
      </div>
  );

}


export default connect(mapStateToProps, mapDispatchToProps)(Layout);
